import React, { createContext, useState, useContext, useRef, useEffect } from 'react';
import { bookingsStore, configStore } from '../state/CommonState';

const PricingContext = createContext();

export const usePricing = () => useContext(PricingContext);

export const PricingContextProvider = ({ children }) => {
 
    const [rec,setRec] = useState(null);
    const [isInidaDoor,setIndiaDoor] = useState(false);
    const [showShipperPDA,setShowShipperPDA] = useState({showShipper:false,include:false,currentStatus:false});
    const [disableInclusion,setDisable] = useState(false);
    const [changeCount,setChangeCount] = useState(0);
    const [total,setTotal] = useState(0);
    const changeCharges = (included,chargeDescription)=>{
        if(included){
            included = "Yes"
        }else{
            included = "No"
        }
        
        let elem = rec.chargesList.find(e1 => chargeDescription == e1.chargeDescription)
        if (elem && elem.isIncluded != included) {
            elem.isIncluded = included
            let erate = configStore.getExchangeRate(elem.currency)
            if (!erate || erate === 0) erate = 1.0
            let amt = elem.total * erate
            if (included != 'Yes') amt *= -1    
            rec.quoteValue += amt
            let usdRate = configStore.getExchangeRate('USD')
            rec.quoteValueUSD = rec.quoteValue / usdRate
            setChangeCount((prev)=>++prev);
            // setRec({...rec})
            // bookingsStore.current.contract.quoteValue = rec.quoteValue
            // bookingsStore.current.contract.quoteValueUSD = rec.quoteValueUSD
    }
}


useEffect(()=>{
  if(rec){
   if(rec.shipmentScope.includes("Door") && (rec.origin.includes('[IN') || rec.destination.includes('[IN'))){
    setIndiaDoor(true);
   }
  }
},[rec])
    return (
      <PricingContext.Provider value={{changeCharges,rec,setRec,disableInclusion,setDisable,showShipperPDA,setShowShipperPDA,isInidaDoor,changeCount,total,setTotal}}>
        {children}
      </PricingContext.Provider>
    );
  };
  