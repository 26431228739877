import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { dtactionStore } from "../dtactions/dtaction-store";
import Utils from "../util/Utils";
import { bookingsStore, contractsStore } from "../state/CommonState";
import {
  AddressInput,
  OneDate,
  OneDate1,
  OneDateCurrent,
  OneInput,
  OneInput1,
  OneInput5,
  OneInputPhone,
  OneSelect4,
} from "./act-common";
import { taskStore } from "./task-store";
import { configStore } from "../state/CommonState";
import { CoiCreationKey } from "../my-actions/dsr_static";
import { CoiCreation1 } from "../state/CommonState";
import { myactionsstore } from "../my-actions/store-myactions";
import Api from "../state/Api";
import EntityApi from "../state/entities-api";
import BookingsApi from "../state/bookings-api";
import Taskapi from "./tasks-api";
import { commodityType, commodityType1 } from "../util/commodityType";
import { loader } from "../util/loader";
import { BiPlus } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import ProceedPopup from "./act-coi-confirmation";
import { enquiryStore } from "../enquiry/enquiry-data";

export default function CoiCreationPreview(data) {
  useSnapshot(taskStore);
  useSnapshot(configStore);
  useSnapshot(myactionsstore);
  useSnapshot(bookingsStore);
  useSnapshot(contractsStore);
  useSnapshot(enquiryStore)
  let brec = bookingsStore.current;
  let rec = contractsStore.current;
  let enq = enquiryStore.current;

  const [invoiceData, SetInvoiceData] = useState();
  const [entityData, SetEntityData] = useState();
  const [contractData, SetContractData] = useState();

  const [confrim, setConfirm] = useState(null);


  const loginUserDetails = localStorage.getItem("ag_userrec");
  console.log("XSCs",loginUserDetails)
  let currentUser = JSON.parse(loginUserDetails)
  const [phoneNo, setPhoneNo] = useState(currentUser.aguserRec.phone);
  const [email, setEmail] = useState(currentUser.aguserRec.email);
  const [contactName, setContactName] = useState(currentUser.aguserRec.firstName + " "+currentUser.aguserRec.lastName);

  let task = taskStore.current;
  let stage = task.stage;
  console.log("task", task);
  console.log("brec", brec);
  console.log("rec", rec);

  console.log("invoiceData", invoiceData);
  console.log("invoiceData1", commodityType);
  console.log("SXa", brec.commodityType);
  console.log("invoiceData2", commodityType[brec.commodityType]);
  console.log("invoiceData3", commodityType1[brec.commodityType]);

  // let [inputs, setInputs] = useState([
  //   { name: "containersNo", record: task, label: "Container Number 1" },
  // ]);
  function validEmail(email) {
    // Regular expression pattern for validating email addresses
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }
  useEffect(() => {
    console.log("testing useEffect is called...");

    // if (task.descriptionOfPackaging != "") {
    //   const index = myactionsstore.error.indexOf("descriptionOfPackaging");
    //   if (index > -1) {
    //     myactionsstore.error.splice(index, 1);
    //   }
    // }
    if (phoneNo != "") {
      const index = myactionsstore.error.indexOf("phoneNo");
      if (index > -1) {
        myactionsstore.error.splice(index, 1);
      }
    }
    if (email != "") {
      const index = myactionsstore.error.indexOf("email");
      if (index > -1) {
        myactionsstore.error.splice(index, 1);
      }
    }
    if (contactName != "") {
      const index = myactionsstore.error.indexOf("contactName");
      if (index > -1) {
        myactionsstore.error.splice(index, 1);
      }
    }
    if (task.invoiceDate != "") {
      const index = myactionsstore.error.indexOf("invoiceDate");
      if (index > -1) {
        myactionsstore.error.splice(index, 1);
      }
    }

    if (task.psd != "") {
      const index = myactionsstore.error.indexOf("psd");
      if (index > -1) {
        myactionsstore.error.splice(index, 1);
      }
    }
    // if (task.containerized != "") {
    //   const index = myactionsstore.error.indexOf("containerized");
    //   if (index > -1) {
    //     myactionsstore.error.splice(index, 1);
    //   }
    // }
    // if (
    //   task.containerized &&
    //   task.containerized == "Y" &&
    //   task.containersNo != ""
    // ) {
    //   const index = myactionsstore.error.indexOf("containersNo");
    //   if (index > -1) {
    //     myactionsstore.error.splice(index, 1);
    //   }
    // }
    // if (task.containerized == "N") {
    //   inputs.forEach((e) => {
    //     console.log(e.name);
    //     task[e.name] = "";
    //   });
    //   setInputs([
    //     {
    //       name: "containersNo",
    //       record: task,
    //       label: "Container Number 1",
    //     },
    //   ]);
    // }
    // if (task.dateofCommencementofVoyage != "") {
    //   const index = myactionsstore.error.indexOf("dateofCommencementofVoyage");
    //   if (index > -1) {
    //     myactionsstore.error.splice(index, 1);
    //   }
    // }
    // if (task.invoiceDate != "") {
    //   const index = myactionsstore.error.indexOf("invoiceDate");
    //   if (index > -1) {
    //     myactionsstore.error.splice(index, 1);
    //   }
    // }
  }, [
    phoneNo,
    email,
    contactName,
    task.psd,
    // task.containerized,
    // task.containersNo,
    // task.dateofCommencementofVoyage,
    // task.invoiceDate,
  ]);
  const getEntitydata = async () => {
    let entityData = await EntityApi.viewEntity(task.entityId);

    console.log("entityData", entityData);
    console.log("entityData1", Utils.ddmmyyyy(new Date(task.psd)));
    return entityData;
  };
  useEffect(() => {
    getEntitydata().then((data1) => {
      console.log("jghh", data1);
      SetEntityData(data1);
    });
    Api.getInvoiceData(
      {
        bookingNum: task.bookingNum,
      },
      async (data) => {
        console.log("checkinvoiceData1", data);
        SetInvoiceData(data.data);
      }
    );
    // Api.getContract(rec._id, (data) => {
    //   console.log("contractData", data);
    //   SetContractData(data);
    // });
  }, []);
  useSnapshot(dtactionStore);
  let modalClass = "modal";
  if (configStore.coiModalVisible) modalClass += " is-active";
  console.log("dsfs", CoiCreationKey);
  let k = Object.keys(CoiCreationKey);
  let dd1 = {};
  let l = {};
  for (let i = 0; i < k.length; i++) {
    l[k[i]] = CoiCreationKey[k[i]];
  }
  dd1 = l;

  // const addInput = (index) => {
  //   setInputs([
  //     ...inputs,
  //     {
  //       name: `Container Number ${inputs.length + 1}`,
  //       record: task,
  //       label: `Container Number ${inputs.length + 1}`,
  //     },
  //   ]);
  // };
  // const removeInputBox = (i) => {
  //   const array = [...inputs];
  //   if (array.length > 1) {
  //     array.splice(i, 1);
  //   }
  //   inputs = array;
  //   setInputs(inputs);
  // };
  console.log(dd1);
  console.log("dd1", entityData);
  let orginCountry = Utils.extractCountry(
    CoiCreation1?.riskcommencementlocation
  );
  let destinationCountry = Utils.extractCountry(
    CoiCreation1?.riskendinglocation
  );

  let policyType;
  if (entityData?.entityCountry == orginCountry) {
    policyType = "Export";
  }
  if (entityData?.entityCountry == destinationCountry) {
    policyType = "Import";
  }
  console.log("invoiceData", invoiceData);
  let invoiceFileName;
  console.log(":sc", invoiceData?.files[0]?.label);
  if (
    invoiceData?.files[0]?.label == "Commercial Invoice" &&
    invoiceData?.files[0]?.filename == ""
  ) {
    let f = task?.files?.map((e) => {
      if (e?.label == "Commercial Invoice") {
        invoiceFileName = `${process.env.REACT_APP_API_SERVER}/api/v1/filestore/getfile/${task.bookingNum}/${e.filename}`;
      }
    });
  } else {
    invoiceFileName = `${process.env.REACT_APP_API_SERVER}/api/v1/filestore/getfile/${task.bookingNum}/${task?.files[task?.files.length-1]?.filename}`;
  }

  console.log("fvdv", invoiceFileName);
  console.log("fvdva", task);
  console.log("fvdva1", enq);
 
  //console.log("fvdvaaas", contractData.sum_insured, contractData.premium);

  //console.log(brec.contract.cargoList[0].packageType)
  console.log("Dvsdf", myactionsstore.error);
  return (
    <div
      className={modalClass}
      // onClick={() => {
      //   dtactionStore.modalVisible1 = false;
      // }}
    >
      <ProceedPopup
        data={async (value) => {
          console.log("DCDS", value);
          if (value == 0) {
            loader.show();
            const email = localStorage.getItem("ag_email");
            console.log("CV", email);
            
            // let d = [];
            // inputs.map((e) => {
            //   d.push(task[e.name]);
            // });
            // console.log("ss", d);
            // console.log("ss", d.toString());
            let getGstNumber = entityData?.branch?.map((e)=>{
              return e?.branchAddresses?.map((e1)=>{
               if(e1?.address==brec?.address){
                 return e
               }
               
             }).filter(Boolean)
           }).flat()
            let randomValue;
            randomValue = entityData.entityName+"-"+brec.lastName+"-"+((Math.random() * 10).toString().split(".")[1]);
            await Api.createCoiCreation(
              {
                entityId: task.entityId,
                bookingNum: task.bookingNum,
                Product: "Specific Marine",
                First_Name: entityData.entityName,
                Last_Name: "",
                Phone_No: phoneNo,
                Email: email,
                CommodityId: commodityType[brec.commodityType],
                Gst_Number: getGstNumber[0]?.gst,
                Gst_FilePath: `${process.env.REACT_APP_API_SERVER}/api/v1/filestore/getfile/${entityData.documents[2].pkey}/${entityData.documents[2].fileLink}`,
                Pan_Number: entityData.panNum,
                Pan_FilePath: `${process.env.REACT_APP_API_SERVER}/api/v1/filestore/getfile/${entityData.documents[1].pkey}/${entityData.documents[1].fileLink}`,
                Aadhar_Number: "",
                Aadhar_FilePath: "",            
                Address: brec.address.trim(),
                Pin_Code: entityData.branch[0].branchAddresses[0].pincode,
                Client_Type: "Corporate",
                City: entityData.branch[0].branchAddresses[0].city,
                Policy_Start_Date: Utils.ddmmyyyy(new Date(task.psd)),
                State: entityData.branch[0].state,
                Contact_Person_Name: contactName,
                Invoice_Date: Utils.ddmmyyyy(new Date(CoiCreation1.commericalInvoiceDate)),
                Invoice_Number: CoiCreation1.commericalInvoiceNumber,
                Invoice_FilePath: invoiceFileName,
                Journey_Date: Utils.ddmmyyyy(new Date(task.psd)),
                Description_Of_Packaging:brec.contract.cargoList.length==1 && brec.contract.cargoList[0].packageType!=""?
                  brec.contract.cargoList[0].packageType:"Loose Cartons",
                Transit_Mode: "Sea",
                Policy_Type: policyType,
                Party_State_Name: entityData.branch[0].state,
                Transit_From_Country:enq.originType=="Port"? CoiCreation1.riskcommencementlocation.split(",")[1].split("(")[0].trim():CoiCreation1.riskcommencementlocation.split(",")[CoiCreation1.riskcommencementlocation.split(",").length-1],
                Transit_To_Country:enq.destinationType=="Port"?CoiCreation1.riskendinglocation.split(",")[1].split("(")[0].trim():CoiCreation1.riskendinglocation.split(",")[CoiCreation1.riskendinglocation.split(",").length-1],
                Transit_From_Address:enq.originType=="Port"?  CoiCreation1.riskcommencementlocation.split(",")[1].split("(")[0].trim():CoiCreation1.riskcommencementlocation.split(",")[CoiCreation1.riskcommencementlocation.split(",").length-1],
                Transit_To_Address:enq.destinationType=="Port"?  CoiCreation1.riskendinglocation.split(",")[1].split("(")[0].trim():CoiCreation1.riskendinglocation.split(",")[CoiCreation1.riskendinglocation.split(",").length-1],
                Blawblrrrcngr_No: "",
                Blawblrrrcngr_Date: "",
                Lc_No: "",
                Marks_Containers_No: "",
                Carrier_Name: "",
                Voyage_No: "",
                Eway_Bill_No: "",
                Consignee_Name: "",
                Consignee_Mobile_No: "",
                Consignee_Address: "",
                Invoice_Value: (CoiCreation1.commericalInvoiceValue).toFixed(),
                Sum_Insured: (brec?.sum_insured).toString(),
                premium: (brec?.premium+(brec?.premium*0.18)).toString(),
                partnerTransactionId: randomValue,
                Incoterms: brec.incoTerms.split(" ")[0],
                moduleType: "PURCHASE",
                orgLinkName: "API-Agraga-Bajaj-Specific-Marine",
                calculatorId: "0673516f-6c6a-4c4b-a456-fa76ddbd7ebf",
                createdBy: email,
                //Containerized: task.containerized == "N" ? "No" : "Yes",
                // Marks_Containers_No: d.toString(),
              },
              async (data) => {
                console.log("ahhh", data);
                if (data.status == "failed") {
                  console.log("CXx", data.message);
                  loader.hide();
                  configStore.setModalMessage(data?.message?.split(":")[1]);
                } else {
                  let task = taskStore.current;
                  task.riskcommencementlocation =
                    CoiCreation1.riskcommencementlocation;
                  task.riskendinglocation = CoiCreation1.riskendinglocation;
                  task.incoTerms = brec.incoTerms;
                  task.stage = 2;
                  task.customerStatus = "COMPLETED";
                  task.pendingWith = "AGRAGA";
                  task.coi_creation = data;
                  task.customerCompletedBy = email;
                  task.customerCompletedOn = Date.now();

                  task.files.push({
                    label: "Marine Insurance Policy-" + data.coi_number,
                    filename: data.fileName,
                    approved: "",
                    rejectReason: "",
                  });
                  taskStore.setComplete();
                  console.log("Dvfd", task);

                  await Taskapi.updateTask(taskStore.current);
                  taskStore.triggerActionVisible = false;
                  taskStore.myActionVisible = false;
                  loader.hide();
                  configStore.setModalMessage(
                    "Your Marine Insurance Policy has been issued successfully."
                  );
                }
              }
            );
          }
        }}
      ></ProceedPopup>
      <div className="modal-background"></div>
      <div
        className="modal-card"
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{ borderRadius: "10px", width: "900px" }}
      >
        <section className="modal-card-body">
          <h2 style={{ color: "blue" }}>Booking Number : {task.bookingNum}</h2>
          <br />

          {/* Personal Details */}

          <h1 style={{ color: "blue", fontWeight: "bold", fontSize: "22px" }}>
            Personal Details
          </h1>
          <br></br>
          <div className="columns is-multiline is-mobile">
          
            {/* <div className="column is-6 py-2">
              <OneInput5
                disabled={true}
                name="lastName"
                record={brec}
                label={dd1["lastName"]}
              />
            </div> */}
            <div className="column is-6 py-2">
              {/* <OneInputPhone
                name="phone"
                record={currentUser.aguserRec}
                label={dd1["phoneNo"]}
              /> */}
                <>
            <div className="columns">
                <div className="column is-6 py-2">
                    <div style={{ marginRight: '5px', marginTop: '3px', fontWeight: "bold" }}>{"Phone No"}</div>
                </div>
                <div className="column is-6 py-2">
                    {(currentUser.aguserRec) ? <input style={{ width: "100%" }} 
                        value={phoneNo} className={myactionsstore.error.indexOf("phoneNo") == -1? "input is-small" : "input is-small is-danger"}
                        onChange={(e) => {
                            setPhoneNo(e.target.value)
                          
                        }}
                        min="10"
                        onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                        onInput={(e) => {
                            e.target.value = e.target.value.replace(/\D/g, '').slice(0, 10);
                        }}

                    /> : <></>
                    }
                </div>
            </div>
        </>
            </div>
            <div className="column is-6 py-2">
              <>
            <div className="columns">
                <div className="column is-6 py-2">
                    <div style={{ marginRight: '5px', marginTop: '3px', fontWeight: "bold" }}>{"Email"}</div>
                </div>
                <div className="column is-6 py-2">
                    {(currentUser.aguserRec) ? <input style={{ width: "100%" }} 
                        value={email} className={myactionsstore.error.indexOf("email") == -1? "input is-small" : "input is-small is-danger"}
                        onChange={(e) => {
                            setEmail(e.target.value)
                          
                        }}
                       
                    /> : <></>
                    }
                </div>
            </div>
        </>
            </div>
          </div>
          <br />

          {/* Business Details */}
          <h1 style={{ color: "blue", fontWeight: "bold", fontSize: "22px" }}>
            Business Details
          </h1>
          <br></br>
         
          <div className="columns is-multiline is-mobile">
          <div className="column is-6 py-2">
              <OneInput5
                disabled={true}
                name="entityName"
                record={entityData}
                label={dd1["entityName"]}
              />
            </div>
              {entityData?.branch?.map((e)=>{
                return e?.branchAddresses?.map((e1)=>{
                 if(e1.address==brec.address){
                  return <div className="column is-6 py-2">
                      <OneInput5
                          name="gst"
                          record={e}
                          disabled={true}
                          label={dd1["gst"]}
                      />
                   </div>
                  }
                })
              })}
              
            <div className="column is-6 py-2">
              <AddressInput
                name="address"
                record={entityData?.branch[0]?.branchAddresses[0]}
                disabled={true}
                label={dd1["address"]}
              />
            </div>
            <div className="column is-6 py-2">
              <OneInput5
                name="city"
                record={entityData?.branch[0]?.branchAddresses[0]}
                disabled={true}
                label={dd1["city"]}
              />
            </div>
            <div className="column is-6 py-2">
             
               <div className="columns">
                <div className="column is-6 py-2">
                    <div style={{ marginRight: '5px', marginTop: '3px', fontWeight: "bold" }}>{dd1["state"]}</div>
                </div>
                <div className="column is-6 py-2">
                      <input style={{ width: "100%" }} 
                        className={myactionsstore.error.indexOf("state") == -1 ? "input is-small" : "input is-small is-danger"}
                        value={entityData?.branch[0].state.split("/")[0]} 
                        disabled
                     /> 
                </div>
            </div>
            </div>
            <div className="column is-6 py-2">
              <OneInput5
                name="pincode"
                record={entityData?.branch[0]?.branchAddresses[0]}
                disabled={true}
                label={dd1["pincode"]}
              />
            </div>
            {/* <div className="column is-6 py-2">
              <OneInput5
                name="customercontact"
                record={entityData?.logisticInformation[0]}
                disabled={true}
                label={dd1["customercontact"]}
              />
            </div> */}
            <div className="column is-6 py-2">
              <>
            <div className="columns">
                <div className="column is-6 py-2">
                    <div style={{ marginRight: '5px', marginTop: '3px', fontWeight: "bold" }}>{"Contact Person"}</div>
                </div>
                <div className="column is-6 py-2">
                    {(currentUser.aguserRec) ? <input style={{ width: "100%" }} 
                        value={contactName} className={myactionsstore.error.indexOf("contactName") == -1? "input is-small" : "input is-small is-danger"}
                        onChange={(e) => {
                            setContactName(e.target.value)
                          
                        }}
                       
                    /> : <></>
                    }
                </div>
            </div>
        </>
            </div>
         
          </div>
          <br />

          {/* Shipment Details */}
          <h1 style={{ color: "blue", fontWeight: "bold", fontSize: "22px" }}>
            Shipment Details
          </h1>
          <br></br>
          <div className="columns is-multiline is-mobile">
            <div className="column is-6 py-2">
              <OneDate1
                name="commericalInvoiceDate"
                record={CoiCreation1}
                label={dd1["commericalInvoiceDate"]}
                disabled
              />
            </div>
            <div className="column is-6 py-2">
              <OneInput5
                name="transitMode"
                record={{ transitMode: "Sea" }}
                disabled={true}
                label={dd1["transitMode"]}
              />
            </div>
        
            {enq.originType=="Port"?
            <div className="column is-6 py-2">
              <OneInput5
                name="riskcommencementlocation"
                record={{
                  riskcommencementlocation:CoiCreation1?.riskcommencementlocation
                    ?.split(",")[1]
                    ?.split("(")[0],
                  riskendinglocation: CoiCreation1?.riskcommencementlocation
                    ?.split(",")[1]
                    ?.split("(")[0],
                }}
                disabled={true}
                label={dd1["riskcommencementlocation"]}
              />
            </div>:
            <div className="column is-6 py-2">
              <AddressInput
                name="riskcommencementlocation"
                record={{
                  riskcommencementlocation:
                    CoiCreation1?.riskcommencementlocation
                      
                    
                }}
                disabled={true}
                type=""
                label={"Transit From Address"}
              />
            </div>
            }
            {enq.destinationType=="Port"? <div className="column is-6 py-2">
              <OneInput5
                name="riskendinglocation"
                record={{
                  riskendinglocation: CoiCreation1?.riskendinglocation
                    ?.split(",")[1]
                    ?.split("(")[0],
                }}
                disabled={true}
                label={dd1["riskendinglocation"]}
              />
            </div>: <div className="column is-6 py-2">
              <AddressInput
                name="riskendinglocation"
                record={{
                  riskendinglocation: CoiCreation1?.riskendinglocation
                }}
                disabled={true}
                label={"Transit To Address"}
              />
            </div>}
           

            <div className="column is-6 py-2">
              <OneDateCurrent name="psd" record={task} label={dd1["psd"]} />
            </div>
            <div className="column is-6 py-2">
              <OneDateCurrent
                name="journeyDate"
                record={{ journeyDate: task.psd }}
                label={dd1["journeyDate"]}
                disabled
              />
            </div>
            <div className="column is-6 py-2">
              <OneInput5
                name="commericalInvoiceValue"
                record={CoiCreation1}
                disabled={true}
                label={dd1["commericalInvoiceValue"]}
              />
            </div>
          </div>
          <br />

          {/* Cargo Details */}
          <h1 style={{ color: "blue", fontWeight: "bold", fontSize: "22px" }}>
            Cargo Details
          </h1>
          <br></br>
          <div className="columns is-multiline is-mobile">
            <div className="column is-6 py-2">
              <OneInput5
                name="commodityType"
                record={brec}
                disabled={true}
                label={dd1["commodityType"]}
              />
            </div>
            <div className="column is-6 py-2">
              <OneInput5
                name="packageType"
                record={{"packageType":brec.contract.cargoList.length==1 && brec.contract.cargoList[0].packageType!=""
                  ?brec.contract.cargoList[0].packageType:"Loose Cartons"}}
                disabled={true}
                label={dd1["packageType"]}
              />
            </div>
            <div className="column is-6 py-2">
              <OneInput5
                name="incoTerms"
                record={brec}
                disabled={true}
                label={dd1["incoTerms"]}
              />
            </div>
          </div>

          <br />
          <button
            class="button is-danger"
            style={{ float: "right" }}
            onClick={async () => {
              console.log("DSC", task);
              // if (
              //   task.descriptionOfPackaging == "" ||
              //   task.descriptionOfPackaging == "undefined"
              // ) {
              //   myactionsstore.error.push("descriptionOfPackaging");
              // }
              if (phoneNo == "" || phoneNo == "undefined") {
                myactionsstore.error.push("phoneNo");
              }
              if (email == "" || email == "undefined") {
                myactionsstore.error.push("email");
              }
              if (contactName == "" || contactName == "undefined") {
                myactionsstore.error.push("contactName");
              }
              if (task.psd == "" || task.psd == "undefined") {
                myactionsstore.error.push("psd");
              }

              // if (
              //   task.containerized == "" ||
              //   task.containerized == "undefined"
              // ) {
              //   myactionsstore.error.push("containerized");
              // }
              // if (
              //   (task.containerized.toLowerCase() == "yes" &&
              //     task.containersNo == "") ||
              //   task.containersNo == "undefined"
              // ) {
              //   myactionsstore.error.push("containersNo");
              // }

              if (email.length > 0) {
                let b = validEmail(email);
                if (b == false) {
                  console.log("SDVVsd");
                  myactionsstore.error.push("email");
                }
              }
              if (phoneNo.length > 0) {
                let b = phoneNo.length;
                if (b < 10) {
                  myactionsstore.error.push("phoneNo");
                }
              }
             
              // inputs.forEach((e) => {
              //   if (
              //     task.containerized != undefined &&
              //     task.containerized == "Y" &&
              //     task[e.name] == ""
              //   ) {
              //     console.log("SX", e.name);
              //     console.log("SX", task[e.name]);

              //     myactionsstore.error.push(e.name);
              //   }
              // });
              console.log("myactionsstore.error", myactionsstore.error);
              if (myactionsstore.error.length == 0) {
                configStore.coiModalVisible1 = true;
              }
            }}
          >
            Submit
          </button>
          <button
            class="button is-warning"
            onClick={() => {
              configStore.coiModalVisible = false;
              setPhoneNo(currentUser.aguserRec.phone)
              setEmail(currentUser.aguserRec.email)
              setContactName(currentUser.aguserRec.firstName+" "+currentUser.aguserRec.lastName)

              // inputs.forEach((e) => {
              //   console.log(e.name);
              //   task[e.name] = "";
              // });
              // setInputs([
              //   {
              //     name: "containersNo",
              //     record: task,
              //     label: "Container Number 1",
              //   },
              // ]);
              // console.log("DXvs", task);
            }}
          >
            Cancel
          </button>
        </section>
      </div>
    </div>
  );
}
