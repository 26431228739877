import { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import logo from '../aglogo.svg'
import Api from '../state/Api'
import { bookingsStore, configStore, contractsStore, entityStore, loginStore } from '../state/CommonState'
import Utils from '../util/Utils'
import LCLChargeClientView from '../enquiry/LCLChargeClientView'
import ShipmentGraph, { graphNode } from '../my-actions/shipment-graph'
import BookingSchedule from './booking.schedule'
import { rtpStore } from './pricing-store'
import { Button } from '@mui/material'
import { ArrowDownwardRounded, ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import airlogoMap from '../schedules/air-logo'
import { enquiryStore } from '../enquiry/enquiry-data'
import BranchApi from '../state/branch-api'
import EntityApi from '../state/entity-api'
import { BRANCH_STATUS, ENTITY_STATUS } from '../util/constants'
import EntityV2Api from '../state/entities-api'
import AguserApi from '../state/AguserApi'
import EntityCreWarnPopup from '../mui-components/EntityCreWarnPopup'
import { useGlobalState } from '../GlobalState'

function _g(node) {
    let e = new graphNode()
    //Object.assign(e, { r1: 'r1', r2: 'r2', r3: 'r3', r4: 'r4' })
    Object.assign(e, node)
    e.isActive = true
    e.activelines = 2
    return e
}


export default function LCLPricingSchedule(props) {
    const [showCharges, setShowCharges] = useState(false)
    const [ptbVisible, setptbVisible] = useState(true)
    const [EntityCreationPopup, setEntityCreationPopup] = useState(false)
    const { currentMenu } = useGlobalState()
    useSnapshot(contractsStore)
    useSnapshot(bookingsStore)
    let brec = bookingsStore.current
    useSnapshot(configStore)
    useSnapshot(rtpStore)
    let hideme = props.hideme
    let cwfVisible = props.cwfVisible
    let disableBookNow = false;
    let charges = props.pricingRec?.charges
    if( charges && ((charges["Pick-up Charges"]&& charges["Pick-up Charges"].total===0)||(charges["Delivery Charges"]&& charges["Delivery Charges"].total===0))){
disableBookNow = true
    }
    //if (!cwfVisible && !ptbVisible) setptbVisible(true)
    let rec = contractsStore.current
    if (props.contract) rec = props.contract
    let subscript = -1
    if (props.subscript >= 0) subscript = props.subscript

    if (!rec) return <></>

    let e = { ...props.data }
    let isDummy = props.isDummy ? true : false
    //if (props.isDummy) return <div>{JSON.stringify(rec)}</div>
    if (props.isDummy) {
        e = {
            'por': rec.portOfReceipt,
            'pol': rec.portOfLoading,
            'pod': rec.portOfDischarge,
            'fpod': rec.finalPlaceOfDelivery,
            'rep': rec.reworkingPort,
            'VESSEL NAME': '',
            'VOYAGE': '',
            'STUFFING CUT OFF DATE': '2020-01-01',
            'ETD (POL)': '2020-01-01',
            'ETA (POD)': '2020-01-01',
            'CO-LOADER': ''
        }
    }
    let theSchedule = e
    if (!e['POR (CFS / ICD / PORT)']) e['POR (CFS / ICD / PORT)'] = e['POL']
    let srec = {
        por: e.por,
        pol: e.pol,
        pod: e.pod,
        vessel: e['VESSEL NAME'],
        voyage: e['VOYAGE'],
        cutOffDt: e['STUFFING CUT OFF DATE'],
        etdPol: e['ETD (POL)'],
        etaPod: e['ETA (POD)'],
        coLoader: e['CO-LOADER']
    }
    const pod = { location: srec.pod }
    const por = { location: srec.por }
    const pol = { location: srec.pol }

    let showOrigin = false
    let showDestination = false
    if (rec.shipmentScope.toLowerCase().indexOf('door-') >= 0) showOrigin = true
    if (rec.shipmentScope.toLowerCase().indexOf('-door') >= 0) showDestination = true
    let etaDate = Utils.newDate(e.etapod)
    if (e.pod != e.fpod) etaDate = Utils.newDate(e.etafpod)
    let etdDate = Utils.newDate(e.etdpol)
    let days = Utils.getDays(etdDate, etaDate)

    let _date = (dt) => {
        return Utils.formatDateShort(new Date(dt))
    }

    //console.log('LCLCOMPONENT***',srec, srec.por, srec.pol, por.location, pol.location)
    let graphnodes = []
    if (showOrigin) {
        graphnodes.push(_g({
            r2: rec.origin,
            graphic: 'origin'
        }))
    }
    graphnodes.push(_g({
        r1: (props.isDummy) ? '' : 'Cut-off ' + _date(e.cutoffpor),
        r2: por.location,
        r3: (por.location == pol.location && !props.isDummy) ? 'ETD ' + Utils.formatDateShort(etdDate) : ''
    }))
    if (por.location !== pol.location) {
        graphnodes.push(_g({
            r2: pol.location,
            r3: (props.isDummy) ? '' : 'ETD ' + _date(e.etdpol)
        }))
    }
    if (!isDummy) {
        graphnodes.push(_g({
            r1: e['VESSEL NAME'] + '-' + e['VOYAGE'],
            transparent: true
        }))
    }
    if (e.etdrw && e.rep) {
        graphnodes.push(_g({
            r3: 'ETD ' + _date(e.etdrw),
            r2: e.rep
        }))
    }
    if (e.etarw && e.rep) {
        graphnodes.push(_g({
            r1: 'ETA ' + _date(e.etarw),
            r2: e.rep
        }))
    }
    if (e.rep && props.isDummy) {
        graphnodes.push(_g({
            r2: e.rep
        }))
    }
    graphnodes.push(_g({
        r1: (props.isDummy) ? '' : 'ETA ' + _date(e.etapod),
        r2: pod.location
    }))
    if (e.pod != e.fpod) {
        graphnodes.push(_g({
            r1: (props.isDummy) ? '' : 'ETA ' + _date(e.etafpod),
            r2: e.fpod
        }))
    }
    if (showDestination) {
        if(rec.destination2!=undefined && rec.destination2.length>0){
            graphnodes.push(_g({
                multidest:rec.multidest,
                r2: "Multiple",
                graphic: 'destination'
            }))
        }else{
            graphnodes.push(_g({
                r2: rec.destination,
                graphic: 'destination'
            }))
        }
    }

    let grandTotal = 0.0
    let voldis = 0.0
    rec['chargesList'].map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        if (e.chargeDescription == 'Volume Discount') {
            voldis = e.total * erate
            //grandTotal -= voldis
        }
        else if (e.isIncluded == 'Yes')
            grandTotal += e.total * erate
    })
    let usdRate = configStore.getExchangeRate('USD')
    let grandusd = grandTotal / usdRate
    let rrr = props.pricingRec
    let sss = props.pricingRec?.dummyContract

    let airlogo = logo
    async function checkBranchGst(data) {
        let isGstAvail = false
        await data.map((r, i) => {
            if (r.status === BRANCH_STATUS.VERIFIED && r.gst.length > 0 && r.branchAddresses[0].address.length > 0) {
                isGstAvail = true
                return isGstAvail
            }
        }
        )
        return isGstAvail

    }
    if (brec && brec.iata) airlogo = airlogoMap[brec.iata]
    console.log("+=",rec)
    return (
        <>
            <div className="box" style={{ background: "#2A6ED4 !important" }}>
                {!EntityCreationPopup ? (<>
                    {
                (rec && rec.validUpto && rec.validUpto.trim().length>0)?
                <div>
                <div style={{ color: 'grey', width: '100%' }}>Valid for <strong>Shipped-on-Board</strong> upto</div>
                <div  style={{ fontSize: '1.0rem', fontWeight: 'bold', color: '#F64' }}>{Utils.formatDate(new Date(rec.validUpto))}</div>
            </div>:""
            }
                    <div className="columns" style={{ fontSize: '0.8rem' }}>
                        <div className="column is-1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px' }}>
                            <img src={airlogo} />
                        </div>
                        <div className="column is-9">
                            {
                                (days != undefined && Number(days) > 0) ?
                                    <>
                                        <div style={{ color: 'grey', width: '100%', textAlign: 'center' }}>Tentative Ocean Transit Time</div>
                                        <div style={{ fontSize: '0.9rem', width: '100%', textAlign: 'center' }}><strong>{e.transitTime} days</strong></div>
                                    </>
                                    :
                                    <div style={{ color: 'grey', width: '100%', textAlign: 'center' }}>Schedule Awaited</div>
                            }
                            <div >
                                <ShipmentGraph insidepricing={true} graphNodes={graphnodes} />
                            </div>
                        </div>
                        <div className='column is-2' style={{ textAlign: 'center' }}>
                            {
                                (!rec.isFBA || (rec.isFBA && currentMenu == "Bookingsv2"))?
                                <>
                                    {
                                        (rec.quoteValue <= 0) ?
                                            <>
                                                <div style={{ fontSize: '1.0rem' }}><strong>{Utils.N(grandTotal)}</strong></div>
                                                <div style={{ fontSize: '0.8rem' }}>{Utils.N(grandusd, 'USD')}</div>
                                                <div style={{ fontSize: '1.0rem' }}>(Less) Volume Discount <strong>{Utils.N(voldis)}</strong></div>
                                            </> : <>
                                                <div style={{ fontSize: '1.0rem' }}><strong>{Utils.N(rec.quoteValue)}</strong></div>
                                                <div style={{ fontSize: '0.8rem' }}>{Utils.N(rec.quoteValueUSD, 'USD')}</div>
                                            </>
                                    }
                                </>:<>
                                {
                                rec.isFBA &&
                                <>
                                    <div style={{ fontSize: '1.0rem' }}><strong>{Utils.N(rec.quoteValue)}</strong></div>
                                    <div style={{ fontSize: '0.8rem' }}>Per CBM Cost {Utils.N(rec.quoteValue / rec.cbm)}</div>
                                </>
                                }
                                </>
                            }
                            {/*
                        (voldis > 0) ?
                            <>
                                <div style={{ fontSize: '1.0rem' }}>(Less) Volume Discount <strong>{Utils.N(voldis)}</strong></div>
                            </> : <></>
                */}
                            {/*
                        (voldis > 0 && grandTotal - voldis > 0) ?
                            <>
                                <div style={{ fontSize: '1.0rem' }}>(Final Discounted Offer <strong>{Utils.N(grandTotal - voldis)}</strong></div>
                            </> : <></>
            */}
                            <div style={{ fontSize: '0.6rem', color: 'grey' }}>(Taxes, as Applicable)</div>
                            <div className="columns m-0 p-0" >
                                {
                                    
                                    <div className='column is-6 m-0 p-0' style={{textAlign: 'center', 'justifyContent': 'center'}}>
                                        {
                                            (!props.nobutton && props.pricingRec) &&
                                            <>
                                                {
                                                    (!loginStore.isClient()) &&
                                                    <button className='button is-rounded is-small is-info' onClick={() => {
                                                        Api.saveQuoteNoTransform(props.pricingRec)
                                                    }}>Save Quote</button>
                                                }
                                                {
                                                    (loginStore.isClient() && !rec.isFBA) &&
                                                    <button className='button is-rounded is-small is-info' onClick={async () => {
                                                        if (configStore.currentSelectedEntity.entityId.length === 0) {
                                                            setEntityCreationPopup(true)
                                                            return
                                                        }
                                                        await Api.saveQuoteAndContract(props.pricingRec, isDummy)
                                                    }}>Save Contract</button>
                                                    // <Button sx={{textTransform:"capitalize"}}>Save Contract</Button>
                                                }
                                            </>
                                        }
                                    </div>
                                }
                                <div className='column is-6 m-0 p-0'>
                                    {
                                        (!props.nobutton) ?
                                            <>
                                                <button disabled={disableBookNow} className='button is-rounded islink is-small' onClick={async () => {
                                                    if (configStore.currentSelectedEntity?.entityId?.length === 0) {
                                                        setEntityCreationPopup(true)
                                                        return
                                                    }
                                                    EntityV2Api.viewEntity(rec.entityId, (data) => {
                                                        if (data.status === ENTITY_STATUS.DEACTIVATED) {
                                                            configStore.setModalMessage(`Entity ${data.entityName} is Deactivated , please Activate it for Procced to booking `)
                                                        }
                                                        else if (data.entityCountry.toLowerCase() === 'india' && (data.branch[0].gst.length < 1 || data.branch[0].branchAddresses[0].address < 1)) {
                                                            if (loginStore.isClient()) {
                                                                let dataObject = {
                                                                    cb: data?.customer?.crossBorder?.relationshipManager,
                                                                    dt: data?.customer?.domesticTransport?.relationshipManager
                                                                }

                                                                AguserApi.getProfile(dataObject, (data) => {
                                                                    let message = "Please update your KYC before proceeding for the Booking. Please reach out to your Relationship Manager if you face any challenges "

                                                                    if (data?.CB_User || data?.DT_User) {
                                                                        let user = data?.CB_User || data?.DT_User
                                                                        message += `${user?.firstName} on ${user?.phonePrefix} ${user?.phone} or ${user?.email}`
                                                                    } else if (data?.PAT_User) {
                                                                        message += data?.PAT_User?.email
                                                                    }

                                                                    configStore.setModalMessage(message)
                                                                })
                                                            } else {
                                                                configStore.setModalMessage('Please Update at least one GST and address to place the Booking')
                                                            }
                                                        }
                                                        else if (data.entityCountry.toLowerCase() !== 'india' && data.tinNum.length < 1) {
                                                            configStore.setModalMessage('Please Update at least tin Number to place the Booking')
                                                        }
                                                        else {

                                                            rtpStore.routeSubscript = subscript
                                                            bookingsStore.initialize()
                                                            bookingsStore.current.schedule = theSchedule
                                                            bookingsStore.current.graphNodes = graphnodes
                                                            let rec1 = { ...rec }
                                                            bookingsStore.current.contract = { ...rec }
                                                            let filteredCharges = bookingsStore.current.contract.chargesList.filter(e => e.isIncluded == 'Yes')
                                                            bookingsStore.current.contract.chargesList = [...filteredCharges]
                                                            bookingsStore.current.contract.quotationNum = 'SPOT'
                                                            bookingsStore.current.contractNum = 'SPOT'
                                                            bookingsStore.current.contractRefNum = 'SPOT'
                                                            bookingsStore.current.hasToShowDummySchedule = false
                                                            bookingsStore.current.emailid = loginStore.email
                                                            bookingsStore.current.createdFromPricing = true
                                                            bookingsStore.current.contract.entityId = contractsStore.current.entityId
                                                            console.log('\n\n***TC length=', bookingsStore.current.contract.tc.length, '***\n')
                                                            if (isDummy) bookingsStore.current.hasToShowDummySchedule = true

                                                            contractsStore.current = rec
                                                            hideme(true)
                                                            setptbVisible(false)

                                                        }
                                                    })
                                                }}>
                                                    Book Now
                                                </button>
                                            </> : <></>
                                    }
                                </div>
                            </div>


                            <button style={{ marginTop: '5px' }} className="button is-inverted is-rounded is-small is-link" onClick={() => {
                                let rec1 = JSON.parse(JSON.stringify(rec))
                                rec1.tc.push(...rec.temptc)
                                configStore.setModalMessage("", [], false, rec1.tc)
                            }}>Terms and Conditions</button>

                        </div>
                    </div>
                    <div className="columns" style={{ fontSize: '0.8rem' }}>
                        {
                            (showCharges) ?
                                loginStore.isClient() ? <Button size='small' onClick={() => {
                                    setShowCharges(false)
                                }} variant='contained' endIcon={<ArrowDropUp />} fullWidth sx={{
                                    display: "flex", textTransform: "capitalize", alignItems: "center", backgroundColor: "#ffdc7d", color: "black", "&:hover": {
                                        backgroundColor: "#ffdc7d"
                                    }
                                }} >Hide Detailed Pricing</Button> : <button className='button is-warning is-small is-fullwidth' onClick={() => setShowCharges(false)}>
                                    Hide Detailed Pricing
                                    <span className="icon" style={{ marginLeft: '5px' }}>
                                        <i className="fas fa-sort-up"></i>
                                    </span>
                                </button> :
                                loginStore.isClient() ? <Button size="small" onClick={() => {
                                    setShowCharges(true)
                                }} variant='contained' endIcon={<ArrowDropDown />} fullWidth sx={{ textTransform: "capitalize", alignItems: "center" }} >View Detailed Pricing</Button> : <button className='button is-info is-small is-fullwidth'
                                    onClick={() => {
                                        setShowCharges(true)
                                    }}>
                                    View Detailed Pricing
                                    <span className="icon" style={{ marginLeft: '5px' }}>
                                        <i className="fas fa-sort-down"></i>
                                    </span>
                                </button>
                        }

                    </div>
                    {
                        (showCharges) ?
                            <div className="columns" style={{
                                fontSize: '0.8rem',
                                display: 'flex', alignItems: 'center', justifyContent: 'center'
                            }}>
                                {
                                    (rec.shipperchargesList?.length > 0 || rec.consigneechargesList?.length > 0) ?
                                        <>
                                            <div className='column is-6'>
                                                <LCLChargeClientView dummyContract={rec} subscript={subscript} />
                                            </div>
                                            <div className='column is-6'>
                                                {
                                                    rec.shipperchargesList?.length > 0 ?
                                                        <LCLChargeClientView dummyContract={rec} chargesName='shipperchargesList' />
                                                        :
                                                        <LCLChargeClientView dummyContract={rec} chargesName='consigneechargesList' />
                                                }
                                            </div>
                                        </>
                                        :
                                        <div className='column is-12'>
                                            <LCLChargeClientView dummyContract={rec} subscript={subscript} />
                                        </div>
                                }
                            </div>
                            : <></>
                    }
                </>
                ) :
                    <EntityCreWarnPopup cancelfn={(i) => setEntityCreationPopup(false)} />
                }
            </div>

        </>


    )
}