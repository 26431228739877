import { Box, Button, Collapse, Typography } from "@mui/material"
import { HorizontalLine } from "./HorizontalDashedLine"
import logo from "../assets/images/logo.svg";
import truck from "../assets/images/truck.svg";
import prices from "../assets/images/price.svg";
import ship from "../assets/images/ship.svg";
import { bookingsStore, breadCrumbStore, configStore, contractsStore, enquiryState, filtersState, loginStore } from "../state/CommonState";
import viewIcon from "../assets/images/view.svg";
import arrowDown from "../assets/images/arrowdown.svg";
import { useEffect, useState } from "react";
import hideIcon from "../assets/images/hide.svg";
import {PricingDetailsCard} from "../mui-components/PricingDetailsCard";
import wolks from "../assets/images/wolkswagon.svg";
import { LCLBookingPricingDetail } from "../pages/LCLBookingPricingDetail";
import { AIRBookingPricingDetail } from "../pages/AIRBookingPricingDetail";
import { FCLBookingPricingDetail } from "../pages/FCLBookingPricingDetail";
import { enquiryStore } from "../enquiry/enquiry-data";
import { VisibilityTracker } from "./VisibilityTracker";
import Utils from "../util/Utils";
import { SPOTOCEAN } from "../static-data/visibilityrules";
import schLogoMap from "../enquiry/carrier-logo";
import { graphNode } from "../my-actions/shipment-graph";
import { ScheduleGraph } from "./ScheduleGraph";
import { ScheduleGraph2 } from "./ScheduleGraph2";
import EntityV2Api from "../state/entities-api";
import AguserApi from "../state/AguserApi";
import { rtpStore } from "../pricing/pricing-store";
import { truckLoader, TruckLoader } from "./TruckLoader";
import { ENTITY_STATUS } from "../util/constants";
import EntityCreWarnPopup from "./EntityCreWarnPopup";
import { useSnapshot } from "valtio";
import { usePricing } from "../context/PricingContext";
import airlogoMap from "../enquiry/air-logo";
import { Util } from "reactstrap";
import { calcCargoDetails } from "../CargoComp";

export function Transit({days,src}){
  
    const isDayAvailable = days && days > 0;
    return <Box sx={{ display: "flex", flexDirection: "column", minWidth:"105px",maxWidth: "105px", height: "100%", justifyContent:'space-around', alignItems: "center" }}>
    <img src={src || logo} style={{ height: "45%", width: "45%",objectFit:"fill" }} />
   
   { isDayAvailable ? <span style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography sx={{ fontFamily: "Figtree", fontSize: "12px", fontWeight: "bold", color: "black", whiteSpace:"nowrap" }}>{days} Days</Typography>
        <Typography sx={{ fontFamily: "Figtree", fontSize: "12px", color: "#555555",whiteSpace:"nowrap" }}>Transit Time</Typography>
    </span> : <Typography sx={{textAlign:"center",fontFamily:"Figtree",fontSize:"14px"}}>Schedule Awaited</Typography>}
</Box>
}

function TrackerProgress({color,routes}){
    return  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', width: "100%" }}>
        {routes?.map((route,i)=><>
            <Milestone color={color} stage={MileStoneEnum.CUTOFF} place={route?.value} date={route?.subValue} />
    { i < routes.length-1 && <Box sx={{ flex: 1 }}><HorizontalLine width="100%" stroke={color} /></Box>}
        </>)}
    {/* <Milestone color={color} stage={MileStoneEnum.POR} place="Mundra" date="Apr 10" />
    <Box sx={{ flex: 1 }}><HorizontalLine width="100%" stroke={color}/></Box>
    <Milestone color={color} stage={MileStoneEnum.ETD} place="Mundra" date="Apr 10" />
    <Box sx={{ flex: 1 }}><HorizontalLine width="100%" stroke={color} /></Box>
    <Milestone color={color} stage={MileStoneEnum.ETA} place="New York" date="Apr 10" />
    <Box sx={{ flex: 1 }}><HorizontalLine width="100%" stroke={color} /></Box>
    <Milestone color={color} stage={MileStoneEnum.ETA} place="Chicago" date="Apr 10" /> */}
</Box>
}

function Tracker({color,routes}){
    return  <Box sx={{ display: "flex", flexDirection: "column", height: "100%", width:"100%", alignItems: "center", gap: 1 }}>
    <Typography sx={{ fontFamily: "Figtree", fontSize: "14px", color: "black" }}><span style={{ fontWeight: "bold" }}>Cisco</span> America - 3143W / <span style={{ fontWeight: "bold", textTransform: "uppercase", color: "#2C358A" }}>INDAMEX</span></Typography>
    <TrackerProgress routes={routes}/>
</Box>
}

export function ScheduleCard({isSchedule=true}) {
    return <Box sx={{ height: "110px", width: "100%", borderRadius: "12px", backgroundColor: "white", display: 'flex', flexDirection: "row", padding: "8px 10px", alignItems: "center", justifyContent: "space-between" }}>
        <Transit days="28" />
        <Box sx={{ display: "flex",height: "100%", width:isSchedule? "75%" : "85%", alignItems: "center", gap: 1, marginRight : isSchedule ? 0 : "10px" }}>
        {/* <Tracker/> */}
        </Box>
        { isSchedule && <Box>
            <Button size="small" onClick={()=>{
                breadCrumbStore.addCrumb("Cargo Details")
            }} startIcon={<img style={{ width: "90%", height: "90%" }} src={prices} />} variant="contained" sx={{ textTransform: "capitalize" }}>Get Rates</Button>
        </Box>
       }
    </Box>
}

export function ContractCard({onClick}){
    return <Box sx={{ height: "110px", width: "100%", borderRadius: "12px", gap:3,backgroundColor: "#2B6ED4", display: 'flex', flexDirection: "row", padding: "8px 10px", alignItems: "center", justifyContent: "space-between" }}>
        <Box sx={{display:"flex",width:"9%",justifyContent:"center",alignItems:"center"}}>
        <img style={{width:"45px",height:"45px"}} src={wolks} />
        </Box>
        <Box sx={{ display: "flex",height: "100%", flex:1, alignItems: "center", gap: 1, paddingX:"8px",flexDirection:"column" }}>
           <Typography sx={{fontFamily:"Figtree",fontWeight:"bold",fontSize:"14px",color:"white"}}>Available Contracts!</Typography>
           <TrackerProgress color="white"/>
        </Box>
        <Box>
            <Button size="small" onClick={()=>{
               onClick();
            }} startIcon={<img style={{ width: "90%", height: "90%" }} src={prices} />} variant="contained" sx={{ textTransform: "capitalize", color:"#2B6ED4",backgroundColor:"white","&:hover":{
                backgroundColor : "white"
            } }}>Use Contract</Button>
        </Box>
    </Box>
}


function FCLRate({rate,vehicle}){
    return   <span style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
    <Typography sx={{fontFamily:"Figtree",fontSize:"14px",fontWeight:"bold",color:"black",whiteSpace:"nowrap"}}>₹ {rate}</Typography>
    <Typography sx={{fontFamily:"Figtree",fontSize:"10px",color:"black",whiteSpace:"nowrap"}}>Per {vehicle}</Typography>
    </span>
}

function _g(node) {
    let e = new graphNode()
    //Object.assign(e, { r1: 'r1', r2: 'r2', r3: 'r3', r4: 'r4' })
    Object.assign(e, node)
    e.isActive = true
    e.activelines = 2
    return e
}

let _date = (dt) => {
    return Utils.formatDateShort(new Date(dt))
}

function getGraphNodes(rec,e,isDummy){
    let showOrigin = false
    let showDestination = false
    const isLclOrAir = rec.shipmentType.includes("LCL") || rec.shipmentType.toLowerCase().includes("air") ;
    if (rec.shipmentScope.toLowerCase().indexOf('door-') >= 0) showOrigin = true
    if (rec.shipmentScope.toLowerCase().indexOf('-door') >= 0) showDestination = true
    let etaDate = Utils.newDate(e.etapod)
    if (e.pod != e.fpod) etaDate = Utils.newDate(e.etafpod)
    let etdDate = Utils.newDate(e.etdpol)
    let days = Utils.getDays(etdDate, etaDate)
   
    
    if (!e['POR (CFS / ICD / PORT)']) e['POR (CFS / ICD / PORT)'] = e['POL']
    let srec = {
        por: e.por,
        pol: e.pol,
        pod: e.pod,
        vessel: e['VESSEL NAME'],
        voyage: e['VOYAGE'],
        cutOffDt: e['STUFFING CUT OFF DATE'],
        etdPol: e['ETD (POL)'],
        etaPod: e['ETA (POD)'],
        coLoader: e['CO-LOADER']
    }
    const pod = { location: srec.pod }
    const por = { location: srec.por }
    const pol = { location: srec.pol }
    let graphnodes = []
  if(isLclOrAir){
    if (showOrigin) {
        graphnodes.push(_g({
            r2: rec.origin,
            graphic: 'origin'
        }))
    }
   

    graphnodes.push(_g({
        r1: (isDummy || e.cutoffpor===0 ) ? '' : 'Cut-off ' + _date(e.cutoffpor),
        r2: por.location,
        r3: (por.location == pol.location && !isDummy) ? 'ETD ' + Utils.formatDateShort(etdDate) : ''
    }))
    if (por.location !== pol.location) {
        graphnodes.push(_g({
            r2: pol.location,
            r3: (isDummy) ? '' : 'ETD ' + _date(e.etdpol)
        }))
    }
    if (!isDummy) {
        graphnodes.push(_g({
            r1: e['VESSEL NAME'] + '-' + e['VOYAGE'],
            transparent: true
        }))
    }
    if (e.etdrw && e.rep) {
        graphnodes.push(_g({
            r3: 'ETD ' + _date(e.etdrw),
            r2: e.rep
        }))
    }
    if (e.etarw && e.rep) {
        graphnodes.push(_g({
            r1: 'ETA ' + _date(e.etarw),
            r2: e.rep
        }))
    }
    if (e.rep && isDummy) {
        graphnodes.push(_g({
            r2: e.rep
        }))
    }
    graphnodes.push(_g({
        r1: (isDummy) ? '' : 'ETA ' + _date(e.etapod),
        r2: pod.location
    }))
    if (e.pod != e.fpod) {
        graphnodes.push(_g({
            r1: (isDummy ) ? '' : 'ETA ' + _date(e.etafpod),
            r2: e.fpod
        }))
    }
    if (showDestination) {
        graphnodes.push(_g({
            r2: rec.destination,
            graphic: 'destination'
        }))
    }
  }else{  if (showOrigin) {
        graphnodes.push(_g({
            r2: rec.origin,
            graphic: 'origin'
        }))
    }
    graphnodes.push(_g({
        r1: (isDummy || e.cutoffpor===0 ) ? '' : 'Cut-off ' + _date(e.cutoffpor),
        r2: por.location,
        r3: (!isDummy) ? 'ETD ' +  _date(por.location !== pol.location ? e.etdpor : e.etdpol) : ''
    }))
    if (por.location !== pol.location) {
        graphnodes.push(_g({
            r1: (isDummy || !e.etapol) ? '' : 'ETA ' + _date(e.etapol),
            r2: pol.location,
            r3: (isDummy || !e.etdpol) ? '' : 'ETD ' + _date(e.etdpol)
        }))
    }
    if (!isDummy) {
        graphnodes.push(_g({
            r1: e['v1name'] + ' (' + e['s1voy'] +')',
            r2: e.r1code,
            transparent: true
        }))
        if (e.ts1name && e.ts1name.length) {
            graphnodes.push(_g({
                r1: 'ETA ' + _date(e.ts1eta),
                r2: e.ts1name,
                r3: 'ETD ' + _date(e.ts1etd)
            }))
            graphnodes.push(_g({
                r1: e['v2name'] + ' (' + e['s2voy'] +')',
                r2: e.r2code,
                transparent: true
            }))
        }
        if (e.ts2name && e.ts2name.length) {
            graphnodes.push(_g({
                r1: 'ETA ' + _date(e.ts2eta),
                r2: e.ts2name,
                r3: 'ETD ' + _date(e.ts2etd)
            }))
            graphnodes.push(_g({
                r1: e['v3name'] + ' (' + e['s3voy'] +')',
                r2: e.r3code,
                transparent: true
            }))
        }
    }

    graphnodes.push(_g({
        r1: (isDummy || !e.etapod) ? '' : 'ETA ' + _date(e.etapod),
        r2: pod.location,
        r3: (isDummy || e.pod == e.fpod ||  !e.etdpod) ? '' : 'ETD ' + _date(e.etdpod),
    }))
    if (e.pod != e.fpod) {
        graphnodes.push(_g({
            r1: (isDummy|| !e.etafpod) ? '' : 'ETA ' + _date(e.etafpod),
            r2: e.fpod
        }))
    }
    if (showDestination) {
        graphnodes.push(_g({
            r2: rec.destination,
            graphic: 'destination'
        }))
    }}
    return graphnodes;
}

// export const getTotalCBM = (cargoDetails,isDimension,dimensionUnit) => {
   
//        let totTotalVolume = 0
       

//     cargoDetails.forEach((e) => {
//         let totalWeight = 0;
//         let totalVolume = 0;
//         let volPerPackage = 0;
//         if (isDimension) {
//             let l = Utils.toUnit(parseInt(e.dimensions.length||0),dimensionUnit);
//             let w = Utils.toUnit(parseInt(e.dimensions.width||0),dimensionUnit);
//             let h = Utils.toUnit(parseInt(e.dimensions.height||0),dimensionUnit);
//             volPerPackage = (l * w * h) / 1000000.0;
//             volPerPackage = parseFloat(volPerPackage);
//             totalVolume = (volPerPackage) * parseFloat(e.quantity||0);
//         }else{
//             totalVolume = Utils.toUnit(parseFloat(e.totalVolume.value||0),e.totalVolume.unit);
//         }

       
//         totTotalVolume += parseFloat(totalVolume);
//         // cargoStore.totals.totChargeableWeight += parseFloat(e.chargeableWeight)
       
//         totTotalVolume = Utils.R(
//           totTotalVolume
//         );
//       });
//     console.log(" the value of total volume ",totTotalVolume)
//     return totTotalVolume;
// };



export function ChooseScheduleCard({rate,schedule,record,index=-1,contract,graphNode=[],days,canProceedFurtherFromHere=true,isSpot=false}) {
    // useEffect(()=>{
    //    filtersState.filteredList = [...filtersState.filteredList];
    // },[contract.quoteValue])
    
    let shipmentLogo = contract?.scac ? schLogoMap[contract?.scac] : null;
    let airLogo = contract?.airline ? airlogoMap[contract?.airline] : null;
    const [theSchedule,setTheSchedule] = useState(null)
    const [graphNodes,setGraphNodes] = useState(graphNode);
    const {total} = usePricing();
    const isContract = contract.mode==="CONTRACT"
    const isQuote = contract.mode==="QUOTE";
    const isToDisplayPerContainerPrice = enquiryStore.current.shipmentType==="FCL" && enquiryStore.current.shipmentScope==="Port-to-Port" && isSpot


    
    useEffect(()=>{
        let theActualSchedule;
        const rec = contract;
        if(index ===-1 && (schedule==undefined || schedule.length==0)){
            theActualSchedule =  {
                  'por': rec.portOfReceipt,
                  'pol': rec.portOfLoading,
                  'pod': rec.portOfDischarge,
                  'fpod': rec.finalPlaceOfDelivery,
                  'rep': rec.reworkingPort,
                  'VESSEL NAME': '',
                  'VOYAGE': '',
                  'STUFFING CUT OFF DATE': '',
                  'ETD (POL)': '',
                  'ETA (POD)': '',
                  'CO-LOADER': ''
              }
          }else{
            theActualSchedule = schedule;
          }
          setTheSchedule(theActualSchedule);
         
    },[schedule]);

    useEffect(()=>{
        bookingsStore.current.schedule = theSchedule;
        if(theSchedule && graphNode.length===0){
            let isDummy = index===-1;
            let currentGraphNodes = getGraphNodes(contract,{...theSchedule},isDummy);
            setGraphNodes(currentGraphNodes);
        }
    },[theSchedule]);

  
    
    const [open,setOpen] = useState(false);
    return <Box sx={{ width: "100%", borderRadius: "12px", backgroundColor: "white", display: 'flex', flexDirection: "column"}}>
       <Box sx={{ padding: "8px 10px", width:"100%",display:"flex",flexDirection:"row",alignItems: "center", justifyContent: "space-between" }}>
       <Transit src={ enquiryStore.current.shipmentType?.toLowerCase()==="air" ? airLogo : shipmentLogo} days={days ? days : index===-1 ? 0 : schedule.transitTime} />
        <Box sx={{ display: "flex",height: "100%", alignItems: "center", gap: 1,paddingX: "18px",width:"85%",flexDirection:"column",justifyContent:"center" }}>
            {/* <Tracker routes={routes}/> */}
           {/* <VisibilityTracker isViewFull={true} mode={contract?.shipmentType} booking={Utils.getTrackerPoints(schedule,index,SPOTOCEAN,contract?.shipmentType)} />  */}
            <ScheduleGraph2 graphNodes={graphNodes} />
            { contract.schedules?.length===0 && contract.validUpto?.length >0 &&  <div className="flex flex-row items-center gap-1"><p className="text-sm text-[#222222] font-roboto">Valid for Shipped-on-Board upto:</p><p className="text-sm text-blue-dark font-roboto font-bold">{Utils.formatDateBooking(contract.validUpto,false)}</p></div> }
        </Box>
        <Box sx={{display:"flex",flexDirection:"column",gap:"15px",justifyContent:"space-evenly",alignItems: enquiryState.mode.toLowerCase() === "fcl" ? "end" : "end",height:"100%"}}>
            {/* { enquiryState.mode.toLowerCase() === "fcl" ?  
              <Box sx={{display:"flex",flexDirection:"row",gap:1}}>
                <FCLRate rate="23,000" vehicle="20’STD"/>
                <FCLRate rate="43,000" vehicle="20’HQ"/>
                <FCLRate rate="23,000" vehicle="40’STD"/>
              </Box>
             :  */}
             <Box sx={{display:"flex",flexDirection:"column",alignItems:"end"}}>
                {isToDisplayPerContainerPrice &&  <div className="flex flex-row gap-1 items-end">{(()=>{
                  let chargesFound = record?.charges["Ocean Freight"];
                  if(chargesFound){
                    return <>
                    <p className="text-blue-dark text-[28px] font-bold">{Utils.N(chargesFound.origTotal/contract.containerList[0].numContainers,chargesFound.template?.currency)}</p>
                    <p className="text-blue-dark text-[14px] py-[8px] whitespace-nowrap">per {chargesFound.template.containerType}</p>
                    </>
                  }
                })()}</div>}
             <Typography sx={{fontFamily:"Figtree",fontSize:isToDisplayPerContainerPrice?"14px":"24px",fontWeight:"bold",color:isToDisplayPerContainerPrice? "#4BA7DD":"#2C358A"}}>{ !isSpot ? Utils.N(total) : Utils.N(rate)}</Typography>
            {!isSpot && <p className="text-blue-light text-sm font-bold whitespace-nowrap">{(()=>{
                let usdRate = configStore.getExchangeRate('USD');
                return Utils.N(total/usdRate,"USD");
             })()}</p>}
             {enquiryStore.current.shipmentType.toLowerCase()==="lcl" && isSpot && <p className="text-blue-light text-sm font-bold whitespace-nowrap">{Utils.N(contract.quoteValue/(contract.cbm<1?1:contract.cbm))} per CBM</p> }
             <p style={{color:"#AAAAAA",fontSize:"9px"}}>(Taxes as Applicable*)</p>
             </Box>
             {/* } */}
            { canProceedFurtherFromHere && <Button size="small" onClick={()=>{
                let isDummy = index===-1;
                // breadCrumbStore.addCrumb("Booking Summary");
                let theCurrentSchedule = record;
                let entityId = configStore.currentSelectedEntity.entityId;  
                // theCurrentSchedule.quote.quoteSummary.entityId = entityId
                // theCurrentSchedule.quote.quoteData.entityId = entityId
                // theCurrentSchedule.dummyContract.entityId = entityId
                console.log(" the schedule herei in the page ",theSchedule, " the index is ", index);
                breadCrumbStore.state = {selectedSchedule : theCurrentSchedule,theSchedule,subscript:index,graphNodes,contract:contract,days:days ? days : index===-1 ? 0 : schedule.transitTime}
                contract.multidest?.forEach((dest)=>{
                    dest.cargoDetails.forEach((cargo)=>{
                       let calculated = calcCargoDetails(cargo,true);
                       cargo.totalVolume = calculated.totalVolume;
                       cargo.totalWeight = calculated.totalWeight;
                    })
                 })
                // enquiryStore.initializeWithConract(contract);
                console.log(" the contract her eis jhhbjhbvfe jhhbghbefv ihgvhgbviv ", contract)
               breadCrumbStore.addCrumb("Booking Summary") 
            }} variant="contained" sx={{ textTransform: "capitalize",backgroundColor:"#2C358A","&:hover":{
                backgroundColor:"#2C358A"
            } }}>Next</Button>}
        </Box>
       </Box>
       <Box onClick={()=>{
          setOpen((prev)=>!prev);
       }} sx={{width:"100%",backgroundColor:"#4BA7DD",gap:1,justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"row",borderBottomLeftRadius:"12px",borderBottomRightRadius:"12px",paddingY:"4px","&:hover":{
        cursor : "pointer"
       }}}>
         {/* <img style={{width:"16px",height:"16px"}} src={ open ? hideIcon : viewIcon} /> */}
         <Typography sx={{fontWeight:"bold",fontFamily:"Figtree",fontSize:'14px',color:"white"}}>{ open ? "Hide Pricing Details" : "View Pricing Details"}</Typography>
         <img style={{width:"12px",height:"12px",transform:open ? "rotate(180deg)" : "none",filter:"brightness(0) invert(100%)"}} src={arrowDown} />
       </Box>
       <Collapse in={open} timeout="auto" unmountOnExit={false}>
          <Box sx={{display:"flex",justifyContent:"center",alignItems:  enquiryState.mode.toLowerCase() === 'fcl' ? "space-between" : "center",paddingY:"10px",gap:1}}>
          { enquiryStore.current.shipmentType.indexOf("LCL")>=0 && <LCLBookingPricingDetail contract={contract}/>}
        { enquiryStore.current.shipmentType.indexOf("Air")>=0 && <AIRBookingPricingDetail contract={contract}/>}
        { enquiryStore.current.shipmentType.indexOf("FCL")>=0 && <FCLBookingPricingDetail showShipper={true} contract={contract}/>}
          </Box>
       </Collapse>
    </Box>
}

const MileStoneEnum = {
    CUTOFF: "Cutoff",
    POR: "POR",
    ETD: "ETD",
    ETA: "ETA"
}



export function Milestone({ stage, place, date, color }) {
    return <Box sx={{ height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "end", gap: stage === MileStoneEnum.ETD ? 2 : 1 }}>
        {stage === MileStoneEnum.ETD ? <Box sx={{ height: "10px", width: "10px", borderRadius: "50%", backgroundColor: color || "#2C358A" }}></Box> : <Box sx={{ border: "1px solid #AAAAAA", height: "25px", width: "25px", borderRadius: "50%", backgroundColor : color || "transparent" , display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img style={{ height: "13px", width: "13px" }} src={stage === MileStoneEnum.CUTOFF ? truck : ship} />
        </Box>}
        <span style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography sx={{ fontFamily: "Figtree", fontSize: "10px", color: color || "#555555", fontWeight: "bold" }}>{place}</Typography>
            <Typography sx={{ fontFamily: "Figtree", fontSize: "10px", fontWeight: "bold", color: color || "black" }}>{stage} - {date}</Typography>
        </span>
    </Box>
}