import { Autocomplete } from "@mui/material";
import { CustomStyledTextField } from "./ColoredTextfield";
import { AgMuiSelect } from "./AgMuiSelect";
import { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import Api from "../state/Api";
import Newapi from "../state/new-api";
import Utils from "../util/Utils";
import { enquiryStore } from "../enquiry/enquiry-data";
import { AgMuiSelect2 } from "./Controlled";


export default function AgAutoComplete({ options, name, onChange,control, rules, onBlur, defaultValue, onInputChange, selectedValue, fontSize,disabled, ...restProps }) {
  // const [inputValue, setInputValue] = useState('');
  //  const [options,setOptions] = useState([]);

  //  const fetchSuggestions = async (input) => {
  //   try {
  //     setLoading(true);
  //     // const response = await ;
  //     setSuggestions(response.data);
  //   } catch (error) {
  //     console.error('Error fetching suggestions:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };


  //  const handleInputChange = (event, newValue) => {
  //   setInputValue(newValue);
  //   // if (newValue) {
  //   //   fetchSuggestions(newValue);
  //   // }
  // };
  // const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  // useEffect(()=>{
  //    console.log("the options inside the ",options)
  //    if(options.length===0){
  //     // setAutocompleteOpen(false);
  //    }else{
  //     if(autocompleteOpen===false){
  //       setAutocompleteOpen(true);
  //     //  }else{
  //     //   setAutocompleteOpen(false);
  //     //   setAutocompleteOpen(true);
  //      }
  //    }
  // },[options])

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => <Autocomplete
      disabled={disabled}
        value={field.value}
        freeSolo={true}
        size="small"
        filterOptions={(menuoptions, state) => menuoptions}
        onBlur={() => {
          // setAutocompleteOpen(false);
          console.log(" the dirst state ",fieldState.isDirty," the touched statsu ",fieldState.isTouched)
          if(fieldState.isTouched){
          if (onBlur) {
            onBlur(field.value);
          }
        }
        }}
        onChange={(e, val) => {
          field.onChange(val);
          if (selectedValue) {
            selectedValue(e, val);
          }
          if(onChange){
            onChange(val);
          }
          // setAutocompleteOpen(false);
        }}
        onInputChange={(e) => {
          console.log("the en ",e?.target.value)
          if (onInputChange) {
            onInputChange(e?.target.value);
          }
        }}
        disablePortal
        options={options}
        sx={{ width: "100%" }}
        renderInput={(params) => <CustomStyledTextField sx={{ "& .MuiOutlinedInput-root": {
          "&.Mui-focused": {
            fieldset: {
              borderColor: '#2C358A !important', // Default focused color
            },
          },
          // "&.Mui-disabled":{
          //   fieldset:{
          //     backgroundColor:"#F3F3F3"
          //   },
          //   "& .MuiFormLabel-root": {
          //     color: "black !important",
          //   },
          //   "& .MuiInputBase-input.Mui-disabled": {
          //     color: "black !important",
          //     WebkitTextFillColor: "black !important", // For WebKit browsers
          //   },
          // }
        }}}  {...restProps} size="small" {...field} error={!!fieldState?.error} helperText={fieldState?.error?.message} {...params} fullWidth  />}
      />}
    />
  );
}





export function AgMuiUn({ isFba=false, getValues,doorCountry="", isDoor, validateAddress = false, rules,getFieldState, control, clearErrors, selectedValue,trigger, name,disabled, ...restProps }) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(() => []);
  }, [isDoor])

  // function checkcurrent() {
  //   if (record[name].length == 0) {
  //     return false
  //   } else {
  //     if (postcode.length == 0) {
  //       if (!Utils.checkAddress(record[name])) {
  //         return false
  //       } else {
  //         let a = record[name].split(' ')
  //         let l = false
  //         for (let i = 0; i < a.length; i++) {
  //           let k = a[i].replace(',', '').trim()
  //           if (Utils.checkZipcode(k)) {
  //             l = true
  //             i = a.length + 1
  //           }
  //         }
  //         if (!l) {
  //           let a = record[name].split(',')
  //           for (let i = 0; i < a.length; i++) {
  //             let k = a[i].trim()
  //             if (Utils.checkZipcode(k)) {
  //               l = true
  //               i = a.length + 1
  //             }
  //           }
  //         }
  //         if (!l) {
  //           return false
  //         } else {
  //           return true
  //         }
  //       }
  //     } else {
  //       return true
  //     }

  //   }
  // }




  const displaySuggestions = async function (val) {
    if (val) {
      let d = [val,enquiryStore.current.shipmentType];
      if (d.length > 0) {
       let results = await Newapi.get(`${process.env.REACT_APP_API_SERVER}/api/v1/location/fetchlocation4product/${d}/${val}`)
        if (results.error){
          setOptions([])
        }else{
          setOptions(results)
        }
      }
    }
  };


  const getdata = async (inputValue) => {
    if (inputValue && inputValue.length > 2) {
      let results
      let s = encodeURI(inputValue)
      let url = ""
      if(isFba){
        url = `${process.env.REACT_APP_API_SERVER}/api/v1/location/fetchaddressfba/${s}`
      }else{
        url = `${process.env.REACT_APP_API_SERVER}/api/v1/location/fetchaddress2/${s}`
      }
      results = await Newapi.get(url)
      return results;
    } else {
      return []
    }
  }

  function check(value) {
    let isError = false;
    if (value?.length > 0) {
      let l = Utils.getUNCodeFromLocationString(value);
      try {
        if (Utils.isUnLoc1(value)) {

          Api.checkUnloc(l, (data) => {
            isError = false;

            if (data == -1) {
              return
            } else {
              console.log("valid 2");
            }
          })
        } else {
          isError = true;

        }
      } catch (e) {
        console.log("the errror is ", e)
      }

    }
    if (isError) {
        return 'Please select an UN address'
    } else {
        return;
    }
  }

  const validateDoorAddress = (value,doorCountry) => {
    console.log("+##33",doorCountry)
    const getcountry =(d)=>{
      if(d!=undefined && d.trim().length!=0){
        d = d.split(',')
        if(d.length>0){
          return d[d.length-1]
        }
      }else{
        return ""
      }
    }
    if(value==undefined || value.trim().length==0 || value.split(",").length==1){
      return "Invalid Address"
    }else{
      if(doorCountry!=undefined && doorCountry!=""){
        let d = getcountry(doorCountry)
        let d1 = getcountry(value)
        if(d!=d1){
          return "Different countries not allowed."
        }else{
          return;
        }
      }else{
        return;
      }
    }
    let isError = false;
    let postcode = "";
    let startIndex = value?.indexOf("[");
    let endIndex = value?.indexOf("]");

  
    if(endIndex===-1){
      return "* Pin/code is required"
    }
    if (startIndex > -1) {
      postcode = value.substring(startIndex + 1, value.length - 1).split(",")[2];
      if (!postcode) {
        postcode = "";
      }
    }else{
      return "* Pin/code is required"
    }

    if (isDoor && validateAddress) {
      if (!value || value?.length === 0) {
        isError = true;
      } else {
        if (postcode.length === 0) {
          if (!Utils.checkAddress(value)) {
            isError = true;
          } else {
            let a = value.split(' ')
            let l = false
            for (let i = 0; i < a.length; i++) {
              let k = a[i].replace(',', '').trim()
              if (Utils.checkZipcode(k)) {
                l = true
                i = a.length + 1
              }
            }
            if (!l) {
              
              let a = value.split(',')
              for (let i = 0; i < a.length; i++) {
                let k = a[i].trim()
                if (Utils.checkZipcode(k)) {
                  l = true
                  i = a.length + 1
                }
              }
            }else{
              l = false
            }


            if (!l) {
              isError = true;
            } else {
              isError = false;
            }
          }
        } else {
          isError = false;
        }
      }
    }
    if (isError) {
        return "* Pin/code is required"
    } else {
        return;
    }
  }
  const handler = async (input) => {
    if (!isDoor) {
      displaySuggestions(input);
    }

    if (isDoor) {
     
      getdata(input).then((val) => {
        setOptions(val);
      })
    }

  }
  // return disabled ?  <div className="flex p-2 border-solid border-[#D4D4D4] border-1 w-full rounded-[5px] bg-[#F3F3F3] text-black">{getValues(name)}</div> : 
  return <AgAutoComplete  selectedValue={selectedValue} 
  disabled={disabled}
  onBlur={async(value) => {
     if(trigger){
        await trigger(name);
     }

  }}
   rules={{
    ...rules,
    validate: (value) => {
      //let {isTouched} = getFieldState(name)
      if (validateAddress) {
        if(!isDoor){
          let isSelectedFromDropDown = options.some((option)=>value===option);
          if(!isSelectedFromDropDown){
            if(value.indexOf('(') == -1 || value.indexOf(')') == -1){
              return "Invalid UN Location"
            }
          }
        }
        if (isDoor) {
          return validateDoorAddress(value,doorCountry);
        } else {
          return check(value)
        }
      }
    }
  }} onInputChange={handler} control={control} name={name} options={options} {...restProps} />
}
