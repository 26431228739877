import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { dtactionStore } from "../dtactions/dtaction-store";
import Api from "../state/Api";
import { Cols, Col8, Col3, Col5, Col4 } from "../Controls";
import { commodityType, commodityType1 } from "../util/commodityType";
import {
  bookingsStore,
  configStore,
  containerStore,
  contractsStore,
} from "../state/CommonState";
import EntityApi from "../state/entities-api";
import Utils from "../util/Utils";
import { taskStore } from "./task-store";
import { loader } from "../util/loader";

export default function ProceedPopup({ data }) {
  useSnapshot(dtactionStore);
  useSnapshot(configStore);

  let options = ["Yes", "No"];
  const [selected, setSelected] = useState(null);
  function onChange(i) {
    setSelected((prev) => (i === prev ? null : i));
  }

  let modalClass = "modal";
  if (configStore.coiModalVisible1) modalClass += " is-active";

  return (
    <div className={modalClass}>
      <div className="modal-background"></div>
      <div
        className="modal-card"
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{ borderRadius: "10px", width: "900px" }}
      >
        <section className="modal-card-body">
          <Cols>
            <Col8
              style={{
                color: "darkblue",
                "margin-left": "150px",
                "font-size": "25px",
              }}
            >
              Do you want to proceed with Marine Insurance?
            </Col8>
            {/* <Col2>
              <div>
                {options.map((o, i) => (
                  <label key={i}>
                    {o + " "}
                    <input
                      type="checkbox"
                      checked={i === selected}
                      onChange={() => onChange(i)}
                    />
                    <br></br>
                  </label>
                ))}
              </div>
            </Col2> */}
          </Cols>
          <br />
          <button
            class="button is-danger"
            style={{ float: "right" }}
            onClick={async () => {
              data(0);
              setSelected();
              configStore.coiModalVisible1 = false;
            }}
          >
            Submit
          </button>
          <button
            class="button is-warning"
            onClick={() => {
              setSelected();

              configStore.coiModalVisible1 = false;
            }}
          >
            Cancel
          </button>
        </section>
      </div>
    </div>
  );
}

export function PremiumInsurance(props) {
  useSnapshot(dtactionStore);
  useSnapshot(taskStore);
  useSnapshot(configStore);
  useSnapshot(contractsStore);
  useSnapshot(bookingsStore);
  useSnapshot(containerStore)
  let brec = bookingsStore.current;
  let rec = contractsStore.current;
  const [amount, setAmount] = useState(12000);
  const [entityData, SetEntityData] = useState(null);
  const [inputModified, setInputModified] = useState(false); // Track if input was modified
  const [premium, setPremium] = useState(null);
  const [hasError, setHasError] = useState(false); // New state to track input error

  //console.log("entityData", rec);
  //console.log("entityData1", brec);
  console.log("aa",configStore.getExchangeRate("USD"))

  const getEntitydata = async () => {
    let entityData = await EntityApi.viewEntity(rec?.entityId);

    console.log("entityData", entityData);
    ///console.log("entityData", task.entityId);

    return entityData;
  };
  useEffect(() => {
    getEntitydata().then((data1) => {
      SetEntityData(data1);
    });
  }, []);
  let modalClass = "modal";
  if (configStore.coiModalVisible2) modalClass += " is-active";

  const handleSubmit = async () => {
    if (amount=="" ||  amount<12000 ) {
      // If the input is empty, show error
      setHasError(true);
      return;
    } 
    
    else{
      setHasError(false);

      configStore.coiModalVisible2 = false;
      let policyType;
      let orginCountry = Utils.extractCountry(rec.origin);
      let destinationCountry = Utils.extractCountry(rec.destination);
      if (entityData?.entityCountry == orginCountry) {
        policyType = "Export";
      }
      if (entityData?.entityCountry == destinationCountry) {
        policyType = "Import";
      }
      await Api.CoiPremium(
        {
          orgLinkName: "API-Agraga-Bajaj-Specific-Marine-PG",
          Transit_Type: policyType,
          CommodityId: commodityType[rec.commodityType],
          Invoice_Value: amount * configStore.getExchangeRate("USD"),
          Transit_From_Country: orginCountry,
          Transit_To_Country: destinationCountry,
          contractID: rec._id,
          amount:amount
  
        },
        async (data) => {
          console.log("ahhh23",contractsStore.current.shipmentType,contractsStore.current.containerList)
          loader.show()
          console.log("ahhh", data);
          if (data.status == "success") {
            if((data.premium-500)!=0){
              const chargesList = rec.chargesList;
        
              // Remove existing Marine Insurance charge if it exists
              const updatedChargesList = chargesList.filter(e => e.chargeDescription !== "Marine Insurance");
                  
             // Add the updated Marine Insurance charge
             if(contractsStore.current.shipmentType.indexOf("FCL")==-1){
              updatedChargesList.push({
                onaccount:"On Your Account",
                chargeDescription: "Marine Insurance",
                currency: "INR",
                isIncluded: "Yes",
                perShipment: 0,
                total: ((data.premium-500)).toFixed(2),
              });
             }else{
              let l = {
                onaccount:"On Your Account",
                chargeDescription: "Marine Insurance",
                currency: "INR",
                isIncluded: "Yes"
              }
              for (let i = 0; i < contractsStore.current.containerList.length; i++) {
                let cn = contractsStore.current.containerList[i]["containerType"]
                if(i==0){
                  l[cn+"perShipment"] = ((data.premium-500)).toFixed(2)
                  l[cn+"total"] = ((data.premium-500)).toFixed(2)
                }else{
                  l[cn+"perShipment"] = 0
                  l[cn+"total"] = 0
                }
              }
              updatedChargesList.push(l);
             }
             rec.chargesList = JSON.parse(JSON.stringify(updatedChargesList));
             const setquotevalue=(d)=>{
              rec.quoteValue = d.quoteValue+(data.premium-500)
              rec.quoteValueUSD = rec.quoteValue/configStore.getExchangeRate("USD")
             }
              Api.getContractsdata(contractsStore.current._id,setquotevalue)
              
              
            }
           
        
            setTimeout(() => {
              loader.hide()
              props.data(true)
              configStore.setModalMessage("Hurray ! Your Premium for Marine Insurance has been applied.");
            }, 2000);
          } else{
            loader.hide()
            configStore.setModalMessage(data.message);
  
          }
        }
      );
      setAmount(12000);
   
    }
     };
  const getPremium = async () => {
    let policyType;
    let orginCountry = Utils.extractCountry(rec.origin);
    let destinationCountry = Utils.extractCountry(rec.destination);
    if (entityData?.entityCountry == orginCountry) {
      policyType = "Export";
    }
    if (entityData?.entityCountry == destinationCountry) {
      policyType = "Import";
    }
    loader.show()
    await Api.CoiPremium(
      {
        orgLinkName: "API-Agraga-Bajaj-Specific-Marine-PG",
        Transit_Type: policyType,
        CommodityId: commodityType[rec.commodityType],
        Invoice_Value: amount * configStore.getExchangeRate("USD"),
        Transit_From_Country: orginCountry,
        Transit_To_Country: destinationCountry,
        contractID: rec._id,
        amount:amount

      },
      async (data) => {
        setPremium(data.premium);
        if (data.status == "success") {
          loader.hide()
          setPremium(data.premium);
        } else{
          loader.hide()
          configStore.setModalMessage(data.message);
        }
      }
    );
  };
  useEffect(() => {
    if (amount > 12000 ) {
      getPremium();
    }
  }, [amount, inputModified]);
  return (
    <div
      className={modalClass}
      // onClick={async () => {
      //   if (inputModified && !isTyping) {
      //     handleSubmit(); // Only call handleSubmit if input was modified
      //   }
      //   if (inputModified == false && amount <= 12000 && !isTyping) {
      //     handleSubmit();
      //   }
      // }}
    >
      <div className="modal-background"></div>
      <div
        className="modal-card"
        onClick={(e) => {
        e.stopPropagation();
        }}
        style={{ borderRadius: "10px", width: "900px" }}
      >
        <section className="modal-card-body">
          <Cols>
            <Col4 style={{ color: "darkblue" }}>
              Insurance Declared Value (IDV) :
            </Col4>
            <Col5>
              <div class="field">
                <div
                  class="input-d-wrapper"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    position: "relative",
                  }}
                >
                  <input
                    className={"input"}
                    type="number"
                    value={amount}
                    // onFocus={() => setIsTyping(true)} // User starts typing
                    // onBlur={() => setIsTyping(false)} // User stops typing
                    onChange={(event) => {
                      if (event.target.value.length <= 15) {
                        setAmount(event.target.value);
                        //setInputModified(true);
                        setHasError(false)
                      }
                    }}
                    style={{
                      borderColor: hasError ? "red" : "inherit", // Add red border if error exists
                    }}
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                    }
                  />
                  <div
                    style={{
                      width: "70px",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "#1B6B93",
                      color: "#fff",
                      border: "0 10px 10px 0",
                      position: "absolute",
                      outline: "2px solid #1B6B93",
                      cursor: "pointer",
                    }}
                  >
                    USD
                    {/* <img
                      src="./assets/chevron-white.png"
                      alt="chevron"
                      class="chevron-3"
                    /> */}
                    {/* <div class="currency-div d-none">
                      <ul>
                        <li>INR</li>
                        <li>USD</li>
                        <li>DNR</li>
                        <li>EUR</li>
                        <li>DIR</li>
                        <li>QSD</li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
              {hasError && <p style={{ color: "red" }}>IDV value should not less than 12000 and should not be empty.</p>} {/* Error message */}
            </Col5>
          </Cols>
          <Cols>
            {amount == 12000 || amount < 12000 ? (
              <p style={{ marginLeft: "12px" }}>
                You can increase the IDV as per your needs,subject to additional
                premium.
              </p>
            ) : (
              <>
                <div style={{ marginLeft: "12px" }}>
                  <p>
                    You can increase the IDV as per your needs, subject to
                    additional premium.
                  </p>
                  <br></br>
                  <p style={{ fontWeight: "bold" }}>
                    In case of a claim 1 %, of consignment value is the excess.
                  </p>
                </div>
              </>
            )}
          </Cols>
          <Cols>
            <Col8>
              {amount == 12000 || amount < 12000 ? (
                <p style={{ color: "green" }}>
                  No Additional Premium Applicable.
                </p>
              ) : (
                <p style={{ fontWeight: "bold" }}>
                  Additional Premium Of{" "}
                  <span style={{ color: "green" }}>
                    INR {(premium - 500).toFixed(2)}
                  </span>{" "}
                  Applicable
                </p>
              )}
            </Col8>
          </Cols>
          <br />
          <button
            class="button is-danger"
            style={{ float: "right" }}
            onClick={async () => {
              //configStore.coiModalVisible2 = false;
              handleSubmit();
            }}
          >
            Submit
          </button>
          <button
            class="button is-warning"
            onClick={(e) => {
              e.stopPropagation(); // Prevent event bubbling
              setAmount(12000);
              setHasError(false)
              //setInputModified(false); // Reset input modified state
              configStore.coiModalVisible2 = false;
            }}
          >
            Cancel
          </button>
        </section>
      </div>
    </div>
  );
}
