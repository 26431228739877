import { useEffect, useState } from "react";
import destinationIcon from "../assets/images/destinationimg.svg";
import { useFieldArray } from "react-hook-form";
import { Cargo } from "../enquiry/enquiry-data";
import { lclWeightForPort, maxDimensions, packageTypeList, weightPerPackage } from "../StaticData";
import { Quantity } from "../tailwind-components/Quantity";
import { TextWithSelect } from "../mui-components/TextWithSelect";
import { AgMuiSelect2 } from "../mui-components/Controlled";
import LabeledTextField from "../mui-components/LabeledTextField";
import { Add} from "@mui/icons-material";
import deleteicon from "../assets/images/deletei.svg";
import Utils from "../util/Utils";
import { FlexibleCenteredDiv } from "./AgFlexibleCenteredDiv";
import { TableHeading } from "./AgTableHeading";
import { HeadingWithSelect } from "./AgHeadingWithSelect";
import { useSnapshot } from "valtio";
import { AgmodalStore, loginStore } from "../state/CommonState";
export function MultiCargoDetails({form,viewonly,ind,changeToDimension}){
    const LabelStyle = "flex items-center justify-center border-l-[0.5px] border-l-solid border-l-[#D4D4D4] font-bold text-sm min-w-[24px] h-full"
    const DimensionLabelStyle = {height:"25px",width:"80px",padding:0,fontSize:"13px"}
    const { control, watch, getValues, trigger, setValue } = form;
    const { fields: cargoDetails, append: appendCargo, remove: removeCargo } = useFieldArray({
        control: control,
        name: `multidest.${ind}.cargoDetails`
    })
    const [dis,setDis] = useState(false)
    const [showModal,setShowModal] = useState(false)
    useSnapshot(AgmodalStore)
    useSnapshot(loginStore)
    const Mode = watch("mode")
    const mode = Mode.toLowerCase();
    const Scope = watch("scope")
    const [total,setTotal] = useState({ totNumPackages : 0,
        totTotalWeight : 0,
        totTotalVolume : 0,
        totChargeableWeight : 0})
    const [isDimension,setIsDimension] = useState(undefined)
    const isFBA = watch("isFba");
    const palletizationandlabelling = watch("palletizationandlabelling");
    let cargodetailsFromForm = watch(`multidest.${ind}.cargoDetails`);
    const totalWeight = getValues(`multidest.0.cargoDetails.0.totalWeight.unit`);
    const totalVolume = getValues(`multidest.0.cargoDetails.0.totalVolume.unit`);
    const weight = getValues(`multidest.0.cargoDetails.0.weight.unit`);
    const multidest = getValues('multidest')
    const dimensionsUnit = watch("cargoDimensionUnit");
    const calculateWeightAndVolume = ()=>{
        if(JSON.stringify({ totNumPackages : 0,
            totTotalWeight : 0,
            totTotalVolume : 0,
            totChargeableWeight : 0})!=JSON.stringify(total)){
                setTotal({ totNumPackages : 0,
                    totTotalWeight : 0,
                    totTotalVolume : 0,
                    totChargeableWeight : 0})
            }
        if(getValues(`multidest.${ind}.cargoDetails`)!=undefined){
            let totals = Utils.calcCargoDetails(getValues(`multidest.${ind}.cargoDetails`),isDimension,dimensionsUnit,mode,multidest[0]['cargoDetails'][0]);
            setTotal({...totals});
        }
    }
    console.log("+++++++++++",total,getValues(`multidest.${ind}.cargoDetails`),isDimension,dimensionsUnit,mode)
    const generateId = (id,i)=> {
        if(id==undefined){
            let a = i + Date.now() + '-' + Math.floor(Math.random() * 1000)
            cargodetailsFromForm[i].id = a
            return a
        }else{
            return id
        }
    }
    useEffect(()=>{
        if(viewonly || AgmodalStore.modalPage=='Quotev3'){
            if(!dis){
                setDis(true)
            }
        }else{
            if(dis){
                setDis(false)
            }
        }
    },[AgmodalStore])
   useEffect(()=>{
    calculateWeightAndVolume();
   },[JSON.stringify(cargodetailsFromForm)])
    useEffect(()=>{
        setValue(`multidest.0.cargoDetails.0.totalWeight.unit`,weight)
        for(let i = 1; i < cargoDetails.length;i++){
                setValue(`multidest.${ind}.cargoDetails.${i}.weight.unit`,weight)
        }
        calculateWeightAndVolume();
    },[weight])

    useEffect(()=>{
        for(let i = 1; i < cargoDetails.length;i++){
            setValue(`multidest.${ind}.cargoDetails.${i}.totalWeight.unit`,totalWeight)
        }
        calculateWeightAndVolume();
    },[totalWeight])

    useEffect(()=>{
        for(let i = 1; i < cargoDetails.length;i++){
            setValue(`multidest.${ind}.cargoDetails.${i}.totalVolume.unit`,totalVolume)
        }
        calculateWeightAndVolume();
    },[totalVolume])

    useEffect(()=>{
        calculateWeightAndVolume();
    },[dimensionsUnit]);

    const validateGreaterThanZero = (value) => {
        return parseFloat(value) > 0 || 'Required';
    };
    useEffect(()=>{
        calculateWeightAndVolume();
    },[isDimension])

    useEffect(()=>{
        if ((mode === "lcl" && Scope.includes("Door")) || mode === "air" || mode === "fcl" || changeToDimension ) {
            if(!isDimension){
                setIsDimension(true)
            }
        }else{
            if(isDimension || isDimension==undefined){
                setIsDimension(false)
            }
        }
    },[mode,Scope,changeToDimension])
    return <div className="w-full flex flex-col gap-2 items-end">
        {
            isDimension!=undefined && <>
    <div className="flex flex-col w-full">
    {
        multidest.length>1 && <div className={`flex flex-row w-full h-[35px] items-center border-[0.5px] border-solid border-[#DBDEFF] border-b-0 px-[12px]`} style={{background:"#DBDEFF"}}>
        <div className={`flex items-center flex-1`}> 
            <img src={destinationIcon}/>
            <TableHeading title={`Destination ${ind+1} : `} /> 
            {
                getValues(`multidest.${ind}.destination`)!=undefined && getValues(`multidest.${ind}.destination`).length>100?
                <p>&nbsp;{getValues(`multidest.${ind}.destination`).substring(0,100)+"..."}</p>:
                <p>&nbsp;{getValues(`multidest.${ind}.destination`)}</p>  
            }  
        </div>
        </div>
    }
    
    <div className={`flex flex-row w-full h-[35px] items-center border-[0.5px] border-solid border-[#D4D4D4] border-b-0 px-[12px]`}>
    
       <FlexibleCenteredDiv>
           <TableHeading title="Package Type*" />   
       </FlexibleCenteredDiv>
       <FlexibleCenteredDiv>  <TableHeading title="Quantity *" /></FlexibleCenteredDiv>
     <FlexibleCenteredDiv> 
         <HeadingWithSelect disabled={dis} onDropdownChange={()=>{
             //calculateWeightAndVolume();
         }} heading={(isDimension)? mode === "lcl" ? "Weight per package *" : "Weight Per Piece *" : "Total Weight *"} options={["Kgs", "Tons", "Lbs"]} name={`multidest.0.cargoDetails.0.weight.unit`} control={control} />
         {/* <TableHeading title={ (isDimension)? mode === "lcl" ? "Weight per package *" : "Weight Per Piece *" : "Total Weight *"} /> */}
         </FlexibleCenteredDiv>
     <div className={`flex flex-col flex-1`}>    

    {isDimension?<div className="flex flex-row items-center justify-center w-[300px] gap-1 flex-1 ">
         <TableHeading title="Dimensions* x" />
         <AgMuiSelect2 disabled={dis} onChangeHandler={async ()=>{
             //calculateWeightAndVolume();
             await trigger([`multidest`]);
         }}  padding={0} control={control} name={"cargoDimensionUnit" } size="small"  variant="standard" disableUnderline={true} values={ ['Cms', 'MM', 'Inches', "Meters", 'Feet']} options={['Cms', 'MM', 'Inches', "Meters", 'Feet'] } fontColor="#555555" iconColor="#555555"  style={{ fontSize: "12px", alignItems:"center", height:"25px",
             // minWidth:"68px",
             fontSize:"13px",
             fontWeight:"bold",
             color: "#555555",
             borderLeft :0,
             padding:0,
             // paddingRight: "8px",
             backgroundColor:"white", }} />
     </div>: <FlexibleCenteredDiv>
         <HeadingWithSelect disabled={dis} onDropdownChange={()=>{
             calculateWeightAndVolume();
         }} control={control} name={`multidest.0.cargoDetails.0.totalVolume.unit`} options={['CBM', 'cu ft']} heading={"Total Volume*"}/>
         </FlexibleCenteredDiv>}
     </div>
     {cargodetailsFromForm.length > 1 && <div className="w-[35px] h-full"></div>}
     </div>
    {cargodetailsFromForm.map((cargodetail,i)=> <div className={`flex flex-row w-full py-[8px] items-center px-[12px] ${i%2===0 ? "bg-[#F3F3F3]" :  "bg-white border-[0.5px] border-solid border-[#D4D4D4]" }`}>
       <FlexibleCenteredDiv >
       <AgMuiSelect2 getValues={getValues} disabled={viewonly} key={generateId(cargodetail.id,i)} style={{height:"25px",width:"170px"}} onChangeHandler={(value)=>{
         if( isFBA && value==="Loose Cartons"){
              setValue("showPalletLabel",true)
         }else{
             setValue("showPalletLabel",false)
         }
       }} onBlur={()=>{
                 }} rules={{ required: "Required" }}  size="small" fontSize="12px" options={isFBA?["Pallet","Loose Cartons"]:packageTypeList} control={control} name={`multidest.${ind}.cargoDetails.${i}.packageType`} />
       </FlexibleCenteredDiv>
       <FlexibleCenteredDiv >
         <Quantity getValues={getValues} disabled={viewonly} key={generateId(cargodetail.id,i)} callback={(e)=>{setValue(`multidest.${ind}.cargoDetails.${i}.quantity`,e)}} control={control} name={`multidest.${ind}.cargoDetails.${i}.quantity`} rules={{ required: "Required", validate: validateGreaterThanZero }} />
       </FlexibleCenteredDiv>
       <FlexibleCenteredDiv>
         { isDimension ?  <TextWithSelect getValues={getValues} disabled={viewonly}  key={generateId(cargodetail.id,i)} name={`multidest.${ind}.cargoDetails.${i}.weight.value` } control={control} unit={mode === "air" ? weight : totalWeight}  rules={{
                     required: "Required", validate: (value) => {
                         let isValue = validateGreaterThanZero(value);
                         if (typeof isValue === "string") {
                             return isValue
                         } 
                         else if(mode!=="fcl") {
                             let unit =  getValues(`multidest.0.cargoDetails.0.weight.unit`);
                           
                             let maxValue = weightPerPackage[mode][unit.toLowerCase()];
                             if (parseFloat(value) > maxValue) {
                                 // configStore.setModalMessage(`Length for ${packageType} package should be less than ${shipmentType === 'air' ? 400 : 1190}.`)
                                 return `value can't be greater than ${maxValue + " " + unit} `;
                             }
                         }
                     }
                 }} /> : <TextWithSelect getValues={getValues} disabled={viewonly} unit={totalWeight} key={generateId(cargodetail.id,i)}  options={["Kgs", "Tons", "Lbs"]} control={control} name={`multidest.${ind}.cargoDetails.${i}.totalWeight.value`} selectName={`multidest.0.cargoDetails.0.totalWeight.unit`} rules={{ required: "Required", validate: (value) => {
                     let isValue = validateGreaterThanZero(value);
                     const totalWeight =  getValues( `multidest.0.cargoDetails.0.totalWeight.unit`);
                     if (typeof isValue === "string") {
                         return isValue
                     } 
                     else if(mode!=="fcl") {
                         let maxValue = weightPerPackage[mode][totalWeight.toLowerCase()]
                         if (parseFloat(value) > maxValue) {
                             // configStore.setModalMessage(`Length for ${packageType} package should be less than ${shipmentType === 'air' ? 400 : 1190}.`)
                             return `value can't be greater than ${maxValue + " " + totalWeight} `;
                         }
                     }
                 } }} /> }
       </FlexibleCenteredDiv>
       {isDimension ? <FlexibleCenteredDiv className={`flex-none w-[300px]`}>
         <div key={generateId(cargodetail.id,i)} className="flex flex-row w-full gap-1">
             <LabeledTextField disabled={viewonly}  textfieldStyles={DimensionLabelStyle} rules={{
                     required: "Required", validate: (value) => {
                         let isValue = validateGreaterThanZero(value);
                         if (typeof isValue === "string") {
                             return isValue
                         }
                          else if(mode!=="fcl"){
                            
                             let maxValue = maxDimensions[mode][dimensionsUnit.toLowerCase()].length
                             
                             if (value > maxValue) {
                                 // configStore.setModalMessage(`Length for ${packageType} package should be less than ${shipmentType === 'air' ? 400 : 1190}.`)
                                 return `value can't be greater than ${maxValue+" "+dimensionsUnit} `;
                             }
                         }
                     }
                 }} control={control} name={`multidest.${ind}.cargoDetails.${i}.dimensions.length`} endAdornment={<div className={LabelStyle}>L</div>}  />
             <p>x</p>
             <LabeledTextField disabled={viewonly} textfieldStyles={DimensionLabelStyle} rules={{
                     required: "Required", validate: (value) => {
                         let isValue = validateGreaterThanZero(value);
                         if (typeof isValue === "string") {
                             return isValue
                         } 
                         
                         else if(mode!=="fcl"){
                            
                             let maxValue = maxDimensions[mode][dimensionsUnit.toLowerCase()].width
                             
                             if (value > maxValue) {
                                 // configStore.setModalMessage(`Length for ${packageType} package should be less than ${shipmentType === 'air' ? 400 : 1190}.`)
                                 return `value can't be greater than ${maxValue+" "+dimensionsUnit} `;
                             }
                         }
                     }
                 }} control={control} name={`multidest.${ind}.cargoDetails.${i}.dimensions.width`} endAdornment={<div className={LabelStyle}>W</div>}  />
             <p>x</p>
             <LabeledTextField disabled={viewonly} textfieldStyles={DimensionLabelStyle} rules={{
                     required: "Required", validate: (value) => {
                         let isValue = validateGreaterThanZero(value);
                         if (typeof isValue === "string") {
                             return isValue
                         } 
                         else if(mode!=="fcl"){
                            
                             let maxValue = maxDimensions[mode][dimensionsUnit.toLowerCase()].height
                             
                             if (value > maxValue) {
                                 // configStore.setModalMessage(`Length for ${packageType} package should be less than ${shipmentType === 'air' ? 400 : 1190}.`)
                                 return `value can't be greater than ${maxValue+" "+dimensionsUnit} `;
                             }
                         }
                     }   
                 }} control={control} name={`multidest.${ind}.cargoDetails.${i}.dimensions.height`} endAdornment={<div className={LabelStyle}>H</div>}  />
         </div> </FlexibleCenteredDiv> :<FlexibleCenteredDiv> <TextWithSelect getValues={getValues} disabled={viewonly}  unit={totalVolume} key={generateId(cargodetail.id,i)}  options={['CBM', 'cu ft']}
         rules={{ required: "Required", validate: (value) => {
             let isValue = validateGreaterThanZero(value);
             const totalVolume = getValues(`multidest.0.cargoDetails.0.totalVolume.unit`)
             if (typeof isValue === "string") {
                 return isValue
             } 
             else if(mode!=="fcl"){
                
                 let maxValue = lclWeightForPort["totalvolume"][totalVolume.toLowerCase()]
                 
                 if (value > maxValue) {
                     // configStore.setModalMessage(`Length for ${packageType} package should be less than ${shipmentType === 'air' ? 400 : 1190}.`)
                     return `value can't be greater than ${maxValue+" "+totalVolume} `;
                 }
             }
         } }}
         selectName={`multidest.0.cargoDetails.0.totalVolume.unit`} control={control} name={`multidest.${ind}.cargoDetails.${i}.totalVolume.value`} /></FlexibleCenteredDiv>}
        { !viewonly && cargoDetails.length > 1 && 
        <div onClick={()=>{
        //  const currentType = getValues(`multidest.${ind}.cargoDetails.${i}.packageType`);
        //  if(currentType==="Loose Cartons"){
        //      setValue("showPalletLabel",false)
        //  }
         removeCargo(i);
        }} className="w-[35px] h-full flex items-center justify-center cursor-pointer">
<img src={deleteicon}/>
        </div>
                 // : <CenteredBox sx={{width:"34px"}}/>
             }
     </div>)}

    </div>
    <div className="flex flex-row justify-between items-center w-full">
      <div className="flex flex-row gap-2.5"><p className="text-blue-dark font-bold font-barlow">Total Weight:</p><p className="text-yellow font-bold font-barlow">{isNaN(total.totTotalWeight)?0:total.totTotalWeight} Kgs</p></div>
      {/* { !(mode==="air") &&  */}
      <div className="flex flex-row gap-2.5"><p className="text-blue-dark font-bold font-barlow">Total Volume:</p><p className="text-yellow font-bold font-barlow">{isNaN(total.totTotalVolume)?0:total.totTotalVolume} CBM</p></div>
      {!loginStore.isClient() && isFBA && palletizationandlabelling && multidest!=undefined && multidest.length>0 && multidest[ind]?.binstackResponse!=undefined && <div className="flex flex-row gap-2.5"><p className="text-blue-dark font-bold font-barlow">Number of Pallets:</p><p className="text-yellow font-bold font-barlow">{multidest[ind]?.binstackResponse?.totalPallet}<span className="icon" style={{cursor:"pointer"}} onClick={()=>{
        setShowModal(true)
      }}>
                                                    <i className="fas fa-circle-info"></i>
                                                </span></p></div>}
      {/* } */}
      {/* { mode==="air" && <div className="flex flex-row gap-2.5"><p className="text-blue-dark font-bold font-barlow">Chargable Weight:</p><p className="text-yellow font-bold font-barlow">{isNaN(total.totChargeableWeight)?0:total.totChargeableWeight} Kgs</p></div>} */}
      { !viewonly &&
    <button onClick={()=>{
         let newCargo = new Cargo();
         newCargo.totalVolume.unit = totalVolume;
             newCargo.totalWeight.unit = totalWeight;
             newCargo.weight.unit = weight;
         appendCargo({ ...newCargo });
     }} type="button" className="bg-[#F7F7F7] border-[1px] border-solid border-[#E8E8E8] h-[35px] w-[110px] rounded-[6px]">
         <div className="flex h-full w-full flex-row gap-[5px] justify-center items-center font-bold">
             <Add sx={{fontSize:"22px",color:"#2C358A"}}/>
             <p className="text-grey">Add Row</p>
         </div>
     </button>}
    </div>
    {showModal && <div className={`modal ${showModal && "is-active"}`} onClick={() => { 
        setShowModal(false)
        }}>
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px' }} >
                <section className="modal-card-body" >

                <h2 className="text-blue-dark font-bold font-barlow">Pallet Details</h2>
                <div className="table-container">
                <table className="table is-bordered is-striped is-fullwidth is-hoverable ">
                    <thead>
                        <tr>
                            <th>Weight</th>
                            <th>Total Height</th>
                            <th>Total Length</th>
                            <th>Total Width</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            multidest[ind]?.binstackResponse?.palletDetails.map(e=>{
                                return <tr>
                                    <td>{e.weight}</td>
                                    <td>{e.totalHeight}</td>
                                    <td>{e.totalLength}</td>
                                    <td>{e.totalWidth}</td>
                                </tr>
                            })
                        }
                    </tbody>
                    </table></div>
                    <div style={{textAlign:"right"}}>
                    <button class="button is-warning" onClick={() => setShowModal(false)} >close</button>
                    </div>              
                </section>
            </div>
        </div>}
    </>
        }
 </div>
}