import { useSnapshot } from "valtio";
import { configStore, viewBookingStore } from "../state/CommonState";
import { Box } from "@mui/material";
import { PricingDetailsCard2 } from "../mui-components/PricingDetailsCard2";
import Utils from "../util/Utils";
import { useEffect, useState } from "react";
import { Accordions } from "../mui-components/AgMuiAccordion";
import { PricingContextProvider, usePricing } from "../context/PricingContext";

function convertRate(e){
    let rate = 0;
    let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
            let value = e.total * erate
            rate = value           
        return rate;
}

function getChargesList2(chargesList,containerList,isShipperPDA=false,mode){
    let chargeList = [];
    let seperatedAccounts = [];

    chargesList.forEach((charges)=>{
        let index;
        if(charges.onaccount==="On Your Account"){
           index = 0
        }else if(charges.onaccount==="Consignee's Account"){
           index = 1
        }else if(charges.onaccount?.startsWith('Ship')){
          index = 2
        }
        if(charges?.isIncluded==="Yes"){
        if(seperatedAccounts[index]){
            seperatedAccounts[index].push(charges);
         }else{
            seperatedAccounts[index] = [{...charges}]
         }
        }
     })
console.log("the seprataed accounts are ",seperatedAccounts);
     seperatedAccounts.forEach((accountChargesList,index)=>{
        let total = 0.0;
        let containerTotalFrom ;
        let chargeListBasedOn = {
            "0" : "On Your Account",
            "1" : "Consignee's Account",
            "2" : "Shipper's Account"
        }

        if(accountChargesList && accountChargesList.length>0){
       chargeList[index] = {account:chargeListBasedOn[index.toString()],charges:[],total:0.0}
        containerTotalFrom = {};
        accountChargesList.forEach((accountCharges)=>{
            containerList.forEach((container)=>{
                  if(containerTotalFrom[`${container}`]){
                    containerTotalFrom[`${container}`] += convertRate({total:accountCharges[`${container}total`],currency:accountCharges.currency})
                  }else{
                    containerTotalFrom[`${container}`] = convertRate({total:accountCharges[`${container}total`],currency:accountCharges.currency}) ;
                  }
              })
          })
          Object.entries(containerTotalFrom).forEach(([container,value])=>{
            chargeList[index].charges.push({chargeDescription:container,currency:"INR",total:value}) 
            chargeList[index].total += value;
        })
    }
     })

return chargeList;


    // containerList.forEach((container)=>{
    //     let overallTotal = 0.0;
    //     let containerTotal = 0.0;
    //     let containerTotalFrom = `${container}total`;
       

       


    //     chargesList.forEach((charges)=>{
    //        containerTotal += convertRate({total:charges[containerTotalFrom],currency:charges.currency});
    //     })
    //     if(containerTotal>0){
    //         let elementToBePushed = {chargeDescription:container,total:0.0};
    //          if(element.onaccount==="On Your Account"){
    //              if(chargeList[0]){
    //                 chargeList[0].charges.push(elementToBePushed);
    //              }else{
    //                 chargeList[0] = {account:"On Your Account",charges:[elementToBePushed],total:0.0}
    //              }
    //              chargeList[0].total += convertRate(elementToBePushed);
    //          }else if(element.onaccount==="Consignee's Account"){
    //             if(chargeList[1]){
    //                 chargeList[1].charges.push(elementToBePushed);
    //              }else{
    //                 chargeList[1] = {account:"On Consignee Account",charges:[elementToBePushed],total:0.0}
    //              }
    //              chargeList[1].total += convertRate(elementToBePushed);
    //          }else if(element.onaccount?.startsWith('Ship')){
    //             if(chargeList[2]){
    //                 chargeList[2].charges.push(elementToBePushed);
    //              }else{
    //                 chargeList[2] = {account:"On Shipper Account",charges:[elementToBePushed],total:0.0}
    //              }
    //              chargeList[2].total += convertRate(elementToBePushed,containerList);
    //          }
    //     }
    // })
}

function getChargesList(chargesList,containerList,mode){
    let chargeList = [];
    let actualList = [];
    chargesList.forEach(element => {
        let index;
        let total = 0.0;
        containerList.forEach((container)=>{
           total += element[`${container}total`]
        })
        if(total!=0){
            let elementToBePushed = {...element,total};
             if(element.onaccount==="On Your Account"){
                 if(chargeList[0]){
                    chargeList[0].charges.push(elementToBePushed);
                 }else{
                    chargeList[0] = {account:"On Your Account",charges:[elementToBePushed],total:0.0}
                 }
                 index = 0;
                //  chargeList[0].total += convertRate(elementToBePushed,containerList);
             }else if(element.onaccount==="Consignee's Account"){
                if(chargeList[1]){
                    chargeList[1].charges.push(elementToBePushed);
                 }else{
                    chargeList[1] = {account:"On Consignee Account",charges:[elementToBePushed],total:0.0}
                 }
                 index = 1
                //  chargeList[1].total += convertRate(elementToBePushed,containerList);
             }else if(element.onaccount?.startsWith('Ship')){
                if(chargeList[2]){
                    chargeList[2].charges.push(elementToBePushed);
                 }else{
                    chargeList[2] = {account:"On Shipper Account",charges:[elementToBePushed],total:0.0}
                 }
                 index = 2
                //  chargeList[2].total += convertRate(elementToBePushed,containerList);
             }
             if(element?.isIncluded==="Yes" || mode==="CONTRACT" || mode==="QUOTE"){
                chargeList[index].total += convertRate(elementToBePushed,containerList);
                }
        }
    });
    return chargeList;
}

function AccordionViewPricing({filteredChargesList}){
    const [selectedindex,setIndex] = useState(0);
    const accordions = [];
    filteredChargesList?.forEach((charge,i)=>{
        accordions.push({id: i,
        title: charge.account,
        price: Utils.N(charge.total),
        content: <PricingDetailsCard2 isAccordionView={true} charge={charge}/>})
    })
    return  filteredChargesList.length === 1 ? <Box sx={{display:"flex",height:"100%",width:"100%"}}>
         <Box sx={{width:"100%",height:"100%", backgroundColor:"white",borderRadius: "10px", paddingY: "8px", paddingX: "12px"}}> <PricingDetailsCard2 isAccordionView={true} title={filteredChargesList[0].account} charge={filteredChargesList[0]}/></Box>
    </Box> : <Accordions accordions={accordions} selectedIndex={selectedindex} setIndex={setIndex} />
}


function NonAccordionView({filteredChargesList}){
    return <Box sx={{display:"flex",flexDirection:"row",width:"100%",gap:1,justifyContent:"center"}}>
    {filteredChargesList.map((charge)=>{
        
      if(charge){
          return <Box sx={{width:"30%", paddingY: "8px"}}><PricingDetailsCard2 title={charge.account} charge={charge}/></Box>
      }
      return <></>
      })}
  </Box>
}


function FCLPricingContextWrapped({contract,isAccordionView,showShipper=false}){
    const {setRec,setShowShipperPDA,showShipperPDA,setTotal} = usePricing();
    useEffect(()=>{
        setRec(contract);
        setShowShipperPDA((prev)=>({...prev,showShipper}))
    },[contract])



    useEffect(()=>{
            console.log(" the shipper is ",showShipperPDA);
    },[showShipperPDA])
    const filteredChargesList = contract.containerList.length === 1 ? getChargesList(Utils.getChargeListFormatFromBooking(contract),contract.containerList.map((container)=>container.containerType),contract.mode)  : getChargesList2(Utils.getChargeListFormatFromBooking(contract),contract.containerList.map((container)=>container.containerType),showShipperPDA.currentStatus)
    
   if(contract.mode==="CONTRACT"|| contract.mode==="QUOTE"){
        
        setTotal(filteredChargesList[0]?.total)
    }
    // const filteredChargesList = getChargesList(booking.contract?.chargesList,booking.contract.containerList.map((container)=>container.containerType));
    return isAccordionView ? <AccordionViewPricing filteredChargesList={filteredChargesList} /> : <NonAccordionView filteredChargesList={filteredChargesList}/>
}



export function FCLBookingPricingDetail({contract,isAccordionView = false,showShipper=false}){
    // const {booking} = useSnapshot(viewBookingStore).current;
    
    return <FCLPricingContextWrapped contract={contract} isAccordionView={isAccordionView} showShipper={showShipper}/>
}


